export default {
  cliente: {
    id: "",
    pessoa_id: "",
    tipoPessoa: "FISICA",
    codigo: '',
    maiorIdade: true,
    flags: [],
    ativo: true,
    pessoaFisica: {
      nome: "",
      sobrenome:"",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: "",
      idade: 0,
      dadosComplementar: {
        sexo: '',
        num_calcado: '',
        receber_novidades: '',
        quantidade_filhos: '',
        profissao: '',
        data_casamento: '',
        rg: '',
        rg_orgao_emissor: '',
        rg_uf: '',
        rg_data_emissao: '',
        estado_civil: '',
        como_conheceu_id: '',
        colegio: '',
        nome_pai: '',
        nome_mae: '',
        cnh_cliente: '',
        nome_conjugue: '',
        cpf_conjugue: '',
        rg_conjugue: '',
        rg_orgao_emissor_conjugue: '',
        rg_data_emissao_conjugue: '',
        data_nascimento_conjugue: '',
        email_conjugue: '',
        telefone_conjugue: '',
      },
      files: {
        doc_rg_frente: [],
        doc_rg_verso: [],
        doc_cnh_frente: [],
        doc_cnh_verso: [],
        doc_comprovante_renda: [],
        doc_comprovante_endereco: [],
      }
    },
    pessoaJuridica: {
      id:"",
      pessoa_id: "",
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: "",
      nome_responsavel: "",
    },
    enderecos: [],
    emails: [],
    contatos: [],
    contrato: {
      id: "",
      data_ini_vig: new Date(),
      data_fim_vig: "",
      codigo: "",
      tempo_contrato: "",
      unidade_tempo: "",
      cliente_id: "",
      unidade_id: "",
      usuario_id: "",
      situacao: "AGUARDANDO_APROVACAO",
      produtos: [],
      template_versao_id: "",
      assinar_contrato: false,
      cobrar_multa: false,
      tipo_multa: '',
      valor_multa: '0',
      agrupar_vencidas: false,
      bonus_pont: false,
      porc_bonus_pont: '0'
    }
  },
  responsavel: {
    id: "",
    tipoPessoa: 'FISICA',
    pessoaFisica: {
      id: "",
      pessoa_id: "",
      nome: "",
      sobrenome:"",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: "",
      maiorIdade: true,
      dadosComplementar: {
        sexo: '',
        num_calcado: '',
        receber_novidades: '',
        quantidade_filhos: '',
        profissao: '',
        data_casamento: '',
        rg: '',
        rg_orgao_emissor: '',
        rg_uf: '',
        rg_data_emissao: '',
        como_conheceu_id: '',
        estado_civil: '',
        colegio: '',
        nome_pai: '',
        nome_mae: '',
        cnh_cliente: '',
        nome_conjugue: '',
        cpf_conjugue: '',
        rg_conjugue: '',
        rg_orgao_emissor_conjugue: '',
        rg_data_emissao_conjugue: '',
        data_nascimento_conjugue: '',
        email_conjugue: '',
        telefone_conjugue: '',
      }
    },
    pessoaJuridica: {
      id:"",
      pessoa_id: "",
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: "",
      nome_responsavel: ""
    },
    enderecos: [],
    emails: [],
    contatos: [],
  },
  senha: "",
  tabelaPrecoMaterial: null,
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  produtos: {
    list: []
  },
  servico:{
    produto_id: '',
    descricao: '',
    formapagto_id: '',
    forma_pagto: '',
    valor: '',
    num_parcelas: '',
    contfin_id: '',
    conta_fin: '',
    venc_prim_parcela: '',
    gerar_nfse: false,
    recorrencia: false,
    parcelado: false,
    recorrenciaACada: 1,
    tipoRecorrencia: 'M',
    qtdRecorrencia: 1,
    intervalo_parcela: '',
    cobrar_domingos: 'SIM',
    index: ''
  },
  alteraServico: false,
  informar_contrato: false,
  openModalAjuda: false,
  markdown: '',
  materiais: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  cursos: {
    list: []
  },
  curso: {
    curso_id: "",
    curso_nome: "",
    duracoes: []
  },
  unidade: {
    id: "",
    descricao: "",
    numero: ""
  },
  tabelaValores: {
    list: []
  },
  tabelaValoresFiltrado: {
    list: []
  },
  planos: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Cliente/Fornecedor...',
  unidades: {
    list: []
  },
  enableDadosPessoa: true,
  enableDadosContrato: false,
  enableDadosPessoaResponsavel: false,
  enableDadosEnderecoResponsavel: false,
  enableDadosEmailResponsavel: false,
  enableDadosContatoResponsavel: false,
  enableBuscaResp: true,
  enableDadosComercial: false,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  tipo: 'FRANQUEADOR',
  searchResp: {
    cpf: ''
  },
  funcoes: {
    list: []
  },
  comissoes: [],
  modal: false,
  dialog: {
    ativo: false,
    titulo: "",
    descricao: ""
  },
  logado: {
    unidadetrabalho: {
      tipo: ''
    }
  },
  loadList: true,
  funcionarios: {
    list: []
  },
  permissoesAprovar: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  duracoesCursoSelecionado: [],
  habilitaDocumento: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  edit: false,
  clienteNovo: true,
  contasFinanceira: {
    list: []
  },
  produtoSelecionado:{
    preco: null
  },
  dadosComplementaresPF: {
    list: []
  },
  metodos_divugacoes:{
    list: []
  },
  statusAtivoPessoa: true,
  possui_emissao_nfse: false,
  modalConfirmacaoInativaOpen: false,
  endereco: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contato: {
    numero: "55",
    tipoContato: "COMERCIAL",
    principal: true,
    index: ""
  },
  email: {
    email: "",
    principal: true,
    index: ""
  },
  enderecoResponsavel: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contatoResponsavel:  {
    numero: "55",
    tipoContato: "COMERCIAL",
    principal: true,
    index: ""
  },
  emailResponsavel: {
    email: "",
    principal: true,
    index: ""
  },
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: ['update', 'remover'],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
  informarEndereco: false,
  informarContato: false,
  informarEmail: false,
  informarEnderecoResponsavel: false,
  informarContatoResponsavel: false,
  informarEmailResponsavel: false,
  conta: {
    descricao: "",
    tipo: "A RECEBER",
    valortotal: "",
    numparcela: 1,
    item_id: '',
    tipo_item: 'ITEMRECDESP',
    qtd_parcelas: 1,
    gerar_nfse: false,
    parcelas: [
      {
        baixado: "",
        datavencimento: "",
        valorparcela: "",
        formapagto_id: "",
        documento: "",
        num: "",
        contfin_id: "",
        parcela_editada: false,
        index: 1
      }
    ]
  },
  template_documentos: {
    list: []
  }
}