import React, { useState, useEffect } from 'react';
import './ClienteExterno.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import moment from 'moment';
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import { useParams, useNavigate } from 'react-router-dom';
import initialState from './initialState/view';
import getToken from './../../services/getToken'
import { Grid, TextField, FormControlLabel, Button, Switch, FormLabel, FormControl } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalVisualizarDocumento from './../../components/modals/VisualizarDocumento'
import CloseIcon from '@mui/icons-material/Close';
import UploadImage from './../../services/uploads/UploadImage'
import {filesize} from "filesize";
import CurrencyInput from './../../components/mask/CurrencyInput'
import { ImagesProvider, ThumbnailOptionsProvider, Thumbnails } from "react-thumbnails"
import Tooltip from '@mui/material/Tooltip';
import Tabela from './../../components/Tabela'
import SaveIcon from '@mui/icons-material/Save';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import real from './../../services/real'
import somarValores from './../../services/somarValores'
import contarValores from './../../services/contarValores'
import AddIcon from '@mui/icons-material/Add';
import Table from './../../components/Table'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}

const cpf = require('node-cpf-cnpj');

const ViewCliente = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { clienteId } = useParams();

  const criaUrl = () =>{
    const filtro = JSON.parse(localStorage.getItem('filtro_pessoas'))

    let url = `/pre_cadastro_cliente?limit=${filtro.limit}&offset=${filtro.offset}`

    if(filtro.filtrar_por !== ''){
      url += `&filtrar_por=${filtro.filtrar_por.replaceAll(' ', '%')}&value=${filtro.value.replaceAll(' ', '%')}`
    }

    if(filtro.nome !== ''){
      url += `&nome=${filtro.nome.replaceAll(' ', '%')}`
    }

    if(filtro.cpf_cnpj !== ''){
      url += `&cpf_cnpj=${filtro.cpf_cnpj.replaceAll(' ', '')}`
    }

    if(filtro.telefone !== ''){
      url += `&telefone=${filtro.telefone.replaceAll(' ', '')}`
    }

    return url
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      let url = criaUrl()

      navigate(url);
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const openDocumento = (link, documento) => {
    setState((prevState) => ({
      ...prevState,
      openModalVisualizarDocumento: true,
      documento: {
        nome: documento,
        url: link
      }
    }))
  }

  const formataTelefone = (text) => {
    const telefone = text.trim().replace(/\D/g, "");
    
    let formatoTelefone = '';
    if (telefone.length === 11) {
      formatoTelefone = telefone.replace(/^(\d{2})(\d)/g, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2");
    } else if (telefone.length === 10 || telefone.length === 12) {
      formatoTelefone = telefone.replace(/^(\d{3})(\d)/g, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2");
    } else if (telefone.length === 8) {
      formatoTelefone = telefone.replace(/(\d)(\d{4})$/, "$1-$2");
    }else{
      return telefone
    }
  
    return formatoTelefone
  }

  const handleModalVisualizarDocumento = () => {
    setState((prevState) => ({
      ...prevState,
      openModalVisualizarDocumento: false,
      documento: {
        nome: '',
        url: ''
      }
    }))
  }

  const aprovarDados = async () => {
    let {pessoa, dadosContrato} = state

    try {
      let dados = {
        pre_contrato_id: pessoa.pre_contrato.id,
        status_atual: pessoa.pre_contrato.status_atual.estado_contrato,
        pessoa_id: pessoa._id
      }

      if(pessoa.pre_contrato.status_atual.estado_contrato === 'FINALIZACAO_CONTRATO'){
        if (dadosContrato.data_ini_vig === "") {
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de inicio da vigência.'
            }
          }))
          return
        }
  
        const anoContrato = moment(dadosContrato.data_ini_vig).format('YYYY')
    
        if (parseInt(anoContrato) > 9999) {
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Data de Inicio da Vigência Invalido!'
            }
          }))
          return
        }
  
        if(dadosContrato.produtos.length === 0){
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar ao menos um serviço no contratos!'
            }
          }))
          return
        }
  
        if(dadosContrato.cobrar_multa){
          if(dadosContrato.tipo_multa === ''){
            setState(prevState => ({...prevState,
              disabledButton: false,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar o tipo da multa.'
              }
            }))
            return
          }
  
          if(dadosContrato.valor_multa === ''){
            setState(prevState => ({...prevState,
              disabledButton: false,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar o valor da multa.'
              }
            }))
            return
          }
        }
  
        if(dadosContrato.bonus_pont){
          if(dadosContrato.porc_bonus_pont === '' || !dadosContrato.porc_bonus_pont){
            setState(prevState => ({...prevState,
              disabledButton: false,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a porcentagem do bônus de pontualidade.'
              }
            }))
            return
          }
        }

        let dadosNovoContrato = {
          codigo: dadosContrato.codigo,
          data_ini_vig: dadosContrato.data_ini_vig,
          data_fim_vig: dadosContrato.data_fim_vig,
          unidade_tempo: dadosContrato.unidade_tempo,
          tempo_contrato: dadosContrato.tempo_contrato,
          unidade_id: pessoa.unidade_id,
          cliente_id: pessoa.cliente_id,
          cobrar_multa: dadosContrato.cobrar_multa,
          agrupar_vencidas: dadosContrato.agrupar_vencidas,
          tipo_multa: dadosContrato.tipo_multa,
          valor_multa: parseFloat(dadosContrato.valor_multa.replace(".", "").replace(",", ".")),
          bonus_pont: dadosContrato.bonus_pont,
          porc_bonus_pont: parseFloat(dadosContrato.porc_bonus_pont.replace(".", "").replace(",", ".")),
          produtos: dadosContrato.produtos.map(value => {
            let dados = {
              produto_id:parseInt(value.produto_id),
              valor: parseFloat(value.valor.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
              num_parcelas: value.recorrencia ? parseInt(value.num_parcelas) : value.parcelas.length,
              formapagto_id: value.recorrencia ? parseInt(value.formapagto_id) : '',
              contfin_id: value.recorrencia ? parseInt(value.contfin_id) : '',
              venc_prim_parcela: value.recorrencia ? value.venc_prim_parcela : value.parcelas[0].datavencimento,
              recorrencia: value.recorrencia,
              recorrenciaACada: value.recorrenciaACada,
              tipoRecorrencia: value.tipoRecorrencia,
              qtdRecorrencia: value.qtdRecorrencia,
              gerar_nfse: value.gerar_nfse
            }
  
            if(value.parcelado){
              let parcelas = []
  
              for (const parcela of value.parcelas) {
                parcelas.push({
                  valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
                  datavencimento: parcela.datavencimento,
                  contfin_id: parseInt(parcela.contfin_id),
                  formapagto_id: parseInt(parcela.formapagto_id),
                  num: parcela.index,
                  documento: parcela.documento
                })
              }
  
              dados.parcelas = parcelas
            }
  
            return {
              ...dados           
            }
          }),
          template_versao_id: dadosContrato.template_versao_id || '',
          assinar_contrato: dadosContrato.assinar_contrato
        }

        dados.novoContrato = {...dadosNovoContrato}
      }

      await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/aprovarDados`, dados, getToken())
      
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Etapa aprovada com sucesso.'
        }
      }))
      
      if(pessoa.pre_contrato.status_atual.estado_contrato === 'FINALIZACAO_CONTRATO'){
        navigate(`/cliente/view/${pessoa._id}`)
      }else{
        await consultarDados()
        window.scrollTo(0, 0);
      }

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const formatarStatus = (status) => {
    if(status === "APROVACAO_DADOS")
      return "Aprovação dos Dados"
    if(status === "VISITA_CLIENTE")
      return "Visita Cliente"
    if(status === "REPROVADO")
      return "Reprovado"
    if(status === "FINALIZACAO_CONTRATO")
      return "Finalização do Contrato"
    if(status === "APROVADO")
      return "Aprovado"
  }

  const consultarDados = async () => {
    try {
      const { data: pessoa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/${clienteId}`, getToken())
      
      await consultarTemplatesDocumento(pessoa.unidade_id)
      await consultaProdutos(pessoa.unidade_id)
      // await consultarContasFinanceiras(pessoa.unidade_id)

      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${pessoa.unidade_id}`, getToken())
          
      setState(prevState => ({...prevState,
        possui_emissao_nfse: configuracao ? configuracao.habilitar_emissao : false
      }))

      if(!pessoa.cliente){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Essa pessoa não possui um pré cadastro!'
          }
        }))
  
        backPage()
        return
      }

      if(!pessoa.cliente.pre_contrato){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Essa pessoa não possui um pré cadastro!'
          }
        }))
  
        backPage()
        return
      }

      const { data: dadosComplementaresPF } = await axios.get(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPF/list/${pessoa.unidade_id}`, getToken())

      let enderecos = []
      let contatos = []
      let emails = []

      const auxEnderecos = pessoa.enderecos

      for (let i = 0; i < auxEnderecos.length; i++) {
        enderecos.push({
          id: auxEnderecos[i].id,
          cep_id: auxEnderecos[i].cep_id,
          rua: auxEnderecos[i].logradouro,
          complemento: auxEnderecos[i].complemento,
          numero: auxEnderecos[i].numero,
          bairro: auxEnderecos[i].bairro,
          cidade: auxEnderecos[i].cidade,
          estado: auxEnderecos[i].estado,
          cep: auxEnderecos[i].num_cep,
          principal: auxEnderecos[i].principal,
          tipoEndereco: auxEnderecos[i].tipo,
          index: i + 1
        })
      }

      const auxContatos = pessoa.telefones

      for (let i = 0; i < auxContatos.length; i++) {
        contatos.push({
          id: auxContatos[i].id,
          numero: auxContatos[i].numero,
          tipoContato: auxContatos[i].tipo,
          principal: auxContatos[i].principal,
          index: i + 1
        })
      }

      const auxEmails = pessoa.emails

      for (let i = 0; i < auxEmails.length; i++) {
        emails.push({
          id: auxEmails[i].id,
          email: auxEmails[i].email,
          principal: auxEmails[i].principal,
          index: i + 1
        })
      }
      
      let pessoaFisica = null
      let pessoaJuridica = null
      
      if(pessoa.tipo === 'FISICA'){
        pessoaFisica = {
          id: pessoa.fisica.id,
          nome: pessoa.fisica.nome,
          sobrenome: pessoa.fisica.sobrenome,
          cpf: cpf.format(pessoa.fisica.cpf),
          rg: pessoa.fisica.rg,
          dataNascimento: moment(pessoa.fisica.datanascimento).format('Y-MM-DD')
        }
      }else{
        pessoaJuridica = {
          id: pessoa.juridica.id,
          razaoSocial:pessoa.juridica.razaosocial,
          nomeFantasia:pessoa.juridica.nomefantasia,
          cnpj:pessoa.juridica.cnpj,
          inscricaoEstadual:pessoa.juridica.inscricaoestadual,
          dataFundacao:pessoa.juridica.datafundacao
        }
      }

      const dados = {
        _id: pessoa.id,
        unidade_id: pessoa.unidade_id,
        tipoPessoa: pessoa.tipo,
        cliente_id: pessoa.cliente.id,
        codigo: pessoa.cliente ? pessoa.cliente.codigo : '',
        pessoaFisica,
        pessoaJuridica,
        enderecos,
        emails,
        contatos,
        dadosComplementaresPF: pessoa.tipo === 'FISICA' ? (pessoa.fisica.dados_complementares ? pessoa.fisica.dados_complementares : null) : null,
        pre_contrato: pessoa.cliente.pre_contrato
      }

      let tab = 0

      if(dados.pre_contrato.status_atual.estado_contrato === 'APROVACAO_DADOS')
        tab = 0
      if(dados.pre_contrato.status_atual.estado_contrato === 'VISITA_CLIENTE')
        tab = 1
      if(dados.pre_contrato.status_atual.estado_contrato === 'FINALIZACAO_CONTRATO')
        tab = 2
      if(dados.pre_contrato.status_atual.estado_contrato === 'APROVADO'){
        navigate(`/cliente/view/${pessoa._id}`)
      }        

      setState(prevState => ({...prevState,
        pessoa: dados,
        dadosComplementaresPF,
        tab,
        uploadedFiles: pessoa.midias_pessoa.map((value, key) => {
          return {
            id: value.midia ? value.midia.id : '',
            name: value.midia ? value.midia.nome : '',
            preview: value.midia ? `${window._env_.REACT_APP_API_URL}/static/${value.midia.key}` : '',
            uploaded: value.midia ? true : false,
            url: value.midia ? `${window._env_.REACT_APP_API_URL}/static/${value.midia.key}` : '',
            dimensoes: value.midia ? value.midia.dimensoes : '',
            caminho_uploads: '',
            logo_unidade: value.midia.logo_unidade ? true: false,
            index: key,
          }
        })
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const reprovarContrato = async () => {
    try {
      let {pessoa} = state

      await axios.put(`${window._env_.REACT_APP_API_URL}/pessoa/reprovarPreCadastro/${pessoa.pre_contrato.id}`, {}, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Pré Contrato Reprovado!'
        }
      }))
      
      await consultarDados()
      window.scrollTo(0, 0);

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }
  
  const handleChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      tab: newValue
    }))
  };

  const handleUploadImage = (files) => {
    let {pessoa} = state
    
    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      index: state.uploadedFiles.length + key,
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      caminho_uploads: '',
      url: null,
    }))

    concatUploadedFiles(uploadedFiles)
  }

  const concatUploadedFiles = async (uploadedFiles) => {
    await processUpload(uploadedFiles)
  }

  const processUpload = async (uploadedFile) => {
    const data = new FormData();
    const USER_TOKEN = localStorage.getItem('token')
    let {uploadedFiles, pessoa} = state
    
    data.append("file", uploadedFile[0].file, uploadedFile[0].name);
    
    await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/imagem/${pessoa._id}/${pessoa.unidade_id}`, data, {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      onUploadProgress: e => {
        const progress = parseInt(Math.round((e.loaded * 100) / e.total));

        // let newUploadedFiles = {
        //   ...uploadedFile[0],
        //   progress
        // }
  
        // uploadedFiles = uploadedFiles.concat(newUploadedFiles)
  
        // setState((prevState) => ({
        //   ...prevState,
        //   uploadedFiles
        // }))
      }
    }).then(response => {

      let newUploadedFiles = {
        ...uploadedFile[0],
        uploaded: true,
        id: response.data.id,
        key: response.data.key,
        dimensoes: response.data.dimensoes
      }

      uploadedFiles = uploadedFiles.concat(newUploadedFiles)

      setState((prevState) => ({
        ...prevState,
        uploadedFiles,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Imagem Salva!'
        }
      }))


    }).catch((error) => {
      console.log(error)

      // let newUploadedFiles = {
      //   ...uploadedFile[0],
      //   error: true
      // }

      // uploadedFiles = uploadedFiles.concat(newUploadedFiles)

      setState(prevState => ({...prevState,
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: 'Erro Interno'
        }
      }))
    });
  }

  const removerImagem = async (img) => {
    try {
      await axios.delete(`${window._env_.REACT_APP_API_URL}/pessoa/imagem/${img.id}`, getToken())
    
      const {uploadedFiles} = state

      let index = uploadedFiles.findIndex(param => param.id === img.id)

      uploadedFiles.splice(index, 1)

      setState(prevState => ({...prevState,
        uploadedFiles
      }))

      window.location.reload()
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const verificaUnidadeTempo = (value) => {
    if(value === 'DIA'){
      return 'day'
    }else if (value === 'MÊS'){
      return 'month'
    }else if (value === 'ANO'){
      return 'year'
    }
  }

  const updateFieldContrato = (event) => {
    const { dadosContrato, template_documentos } = state

    const name = event.target.name
    const value = event.target.value

    dadosContrato[name] = value

    if((name === 'data_ini_vig' || name === 'unidade_tempo' || name === 'tempo_contrato') && dadosContrato.data_ini_vig !== '' && dadosContrato.unidade_tempo !== '' && dadosContrato.tempo_contrato !== ''){
      dadosContrato.data_fim_vig = moment(dadosContrato.data_ini_vig).add(dadosContrato.tempo_contrato, verificaUnidadeTempo(dadosContrato.unidade_tempo)).format('YYYY-MM-DD')   
    }

    if(name === "template_versao_id" && value !== ""){
      let versao_selecionada = template_documentos.list.filter(param => param.versao_id = parseInt(value))[0]
      dadosContrato.assinar_contrato = versao_selecionada.assinar
    }else{
      dadosContrato.assinar_contrato = false
    }

    setState(prevState => ({...prevState, dadosContrato }))
  }

  const updateFieldAgruparVencidas = () => {
    let { dadosContrato } = state

    dadosContrato.agrupar_vencidas = !dadosContrato.agrupar_vencidas

    setState((prevState) => ({
      ...prevState,
      dadosContrato
    }))
  }

  const updateFieldCobrarMulta = () => {
    let { dadosContrato } = state

    dadosContrato.cobrar_multa = !dadosContrato.cobrar_multa

    setState((prevState) => ({
      ...prevState,
      dadosContrato
    }))
  }

  const updateFieldAssinar = () => {
    const { dadosContrato } = state
    dadosContrato.assinar_contrato = !dadosContrato.assinar_contrato
    setState(prevState => ({...prevState, dadosContrato }))
  }

  const consultarTemplatesDocumento = async (unidade_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/templatesdocumentos/list`, dados, getToken())

      let array = []

      for (const template of data) {
        for (const versao of template.versoes) {
          if(versao.ativo && versao.publicado && moment(versao.datainicio).isBefore(moment())){
            array.push({
              template_id: template.id,
              versao_id: versao.id,
              titulo: template.titulo,
              assinar: template.assinar,
              versao: versao.versao,
              ativo: versao.ativo
            })
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        template_documentos: {
          list: array
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const updateRecorrencia = () => {
    let {servico} = state

    servico.recorrencia = !servico.recorrencia
    servico.parcelado = false
    servico.num_parcelas = 1
    servico.qtd_parcelas = 1
    servico.recorrenciaACada = 1
    servico.tipoRecorrencia = 'M'
    servico.qtdRecorrencia = 1
    
    setState(prevState => ({...prevState,servico}))
  }

  const updateParcelado = () => {
    let {servico} = state
    servico.parcelado = !servico.parcelado
    servico.recorrencia = false
    setState(prevState => ({...prevState,servico}))
  }

  const updateServico = async (event) => {
    const {servico, formasPagamento, contrato, produtos, dadosContrato, conta} = state

    const name = event.target.name
    const value = event.target.value

    servico[name] = value

    setState(prevState => ({...prevState,servico}))

    if(name === 'formapagto_id'){
      try {

        const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]

        const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${state.pessoa.unidade_id}`, getToken())
  
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira.filter(param => param.tipo_conta === formaPagtoSelecionada.habilita)
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }

    if(name === 'produto_id'){
      const produtoSelecionado = produtos.list.filter(param => param.id === parseInt(value))[0]

      if(produtoSelecionado){
        setState(prevState => ({...prevState,
          produtoSelecionado
        }))
      }else{
        setState(prevState => ({...prevState,
          produtoSelecionado:{
            preco: null
          }
        }))        
      }

    } 

    if(name === 'valor'){
      if(servico.parcelado){
        let valorTotal = parseFloat(value.replaceAll(".", "").replaceAll(",", "."))
  
        let valorDividido = valorTotal / conta.qtd_parcelas
        valorDividido = parseFloat(valorDividido.toFixed(2))
  
        let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)
  
        sobra = parseFloat(sobra.toFixed(2))
  
        for (let i = 0; i < conta.qtd_parcelas; i++) {
          conta.parcelas[i].valorparcela = real(valorDividido)
        }
  
        if (sobra !== 0) {
          conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
        }
  
        conta.valortotal = value

        setState(prevState => ({
          ...prevState,
          conta
        }))
      }
    }
  }
  
  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = real((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const updateFieldGerarNfse = () => {
    let {servico} = state

    servico.gerar_nfse = !servico.gerar_nfse

    setState(prevState => ({...prevState,servico}))
  }

  const updateFieldConta = (event) => {
    const conta = { ...state.conta }

    if (event.target.name === 'valortotal') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    conta[event.target.name] = event.target.value


    setState(prevState => ({...prevState, conta }))
  }

  const mudarParcelas = (event) => {
    const conta = { ...state.conta }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
    
    let valorDividido = valorTotal / qtdParcelasInfo
    
    valorDividido = parseFloat(valorDividido.toFixed(2))  

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date()

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date();
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i));

      array.push({
        baixado: "",
        datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
        valorparcela: real(valorDividido),
        formapagto_id: "",
        documento: "",
        num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array

    setState(prevState => ({...prevState, conta }))
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)

      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = real(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const updateFieldParcela = (event, index) => {
    const {conta, formasPagamento} = state
    
    let value = event.target.value
    let name = event.target.name
    
    if(name === 'formapagto_id'){
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]
      
      conta.parcelas[index - 1].habilita = formaPagtoSelecionada.habilita
      conta.parcelas[index - 1].contfin_id = ""
    }

    conta.parcelas[index - 1][name] = value
    
    setState(prevState => ({...prevState, conta }))
  }

  const verificaDataParcela = (event, index) => {
    const {conta} = state
    conta.parcelas[index - 1].datavencimento_orig = event.target.value
    
    for (let i = index; i < conta.parcelas.length; i++) {
      let count = (i - index ) + 1

      let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

      conta.parcelas[i].datavencimento = novaData
      conta.parcelas[i].datavencimento_orig = novaData
    
    }

    setState(prevState => ({...prevState, conta }))
  }

  const marcaParcelas = (event, index) => {
    
    const {conta, formasPagamento} = state
        
    const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(formaPagtoSelecionada){
        conta.parcelas[i].formapagto_id = event.target.value
        conta.parcelas[i].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[i].contfin_id = ""
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const marcaParcelasContaFin = (event, index) => {
    
    const {conta, contasFinanceira} = state
        
    const contaFinanceiraSelecionada = contasFinanceira.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(contaFinanceiraSelecionada){
        if(conta.parcelas[i].habilita === contaFinanceiraSelecionada.tipo_conta){
          conta.parcelas[i].contfin_id = event.target.value
        }
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const validateServico = () => {
    const {servico, conta} = state

    if(!servico.produto_id) return false
    if(!servico.valor) return false
    if(!servico.parcelado && !servico.recorrencia) return false

    if(servico.parcelado){
      // if(!servico.num_parcelas) return false
    }

    if(servico.recorrencia){
      if(!servico.formapagto_id) return false
      if(!servico.contfin_id) return false
      if(!servico.venc_prim_parcela) return false
      if(!servico.qtdRecorrencia) return false
      if(!servico.recorrenciaACada) return false
      if(!servico.tipoRecorrencia) return false
    }

    return true
  }

  const addServico = () => {

    if (!validateServico()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios do serviço (*)."
        },
        loadingSalvar: false
      }))
      return
    }

    const {servico, produtos, formasPagamento, contasFinanceira, dadosContrato, conta} = state

    let parcelas = []

    if(servico.parcelado){
      for (const parcela of conta.parcelas) {

        if(parcela.valorparcela === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar o valor da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.datavencimento === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a data de vencimento da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.formapagto_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a forma de pagamento da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.contfin_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a conta financeira da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }
      }

      parcelas = [...conta.parcelas]
    }

    let descricao_parcelamento = ''

    if(servico.parcelado){
      let contagem = contarValores(parcelas.map(value => {return value.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")})).sort((a, b) => (a.quantidade > b.quantidade) ? 1 : ((b.quantidade > a.quantidade) ? -1 : 0))
    
      for (let i = 0; i < contagem.length; i++) {
        const element = contagem[i];

        if((i + 1) === contagem.length){
          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}`
        }else{
          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}, `
        }
      }
    }

    const produto_selecionado = produtos.list.filter(param => param.id === parseInt(servico.produto_id))[0]
    const forma_selecionada = formasPagamento.list.filter(param => param.id === parseInt(servico.recorrencia ? servico.formapagto_id : parcelas[0].formapagto_id))[0]
    const conta_fin_selecionada = contasFinanceira.list.filter(param => param.id === parseInt(servico.recorrencia ? servico.contfin_id : parcelas[0].contfin_id))[0]
    
    let obj = {
      produto_id: parseInt(servico.produto_id),
      descricao: produto_selecionado.descricao,
      formapagto_id: servico.recorrencia ? parseInt(servico.formapagto_id) : '',
      forma_pagto: forma_selecionada.descricao,
      valor: servico.valor,
      num_parcelas: servico.recorrencia ? parseInt(servico.num_parcelas) : parcelas.length,
      contfin_id: servico.recorrencia ? parseInt(servico.contfin_id) : '',
      conta_fin: conta_fin_selecionada.descricao,
      venc_prim_parcela: servico.recorrencia ? servico.venc_prim_parcela : parcelas[0].datavencimento,
      recorrencia: servico.recorrencia,
      parcelado: servico.parcelado,
      recorrenciaACada:servico.recorrenciaACada,
      tipoRecorrencia: servico.tipoRecorrencia,
      qtdRecorrencia:servico.qtdRecorrencia,
      gerar_nfse: servico.gerar_nfse,
      parcelas,
      descricao_parcelamento: servico.recorrencia ? `${servico.qtdRecorrencia}x de R$ ${servico.valor}`  : descricao_parcelamento,
    }

    if(servico.index === ''){
      dadosContrato.produtos.push({
        ...obj,
        index: dadosContrato.produtos.length,
      })
    }else{
      dadosContrato.produtos[servico.index] = {
        ...obj,
        index: servico.index,
      }
    }

    setState(prevState => ({...prevState,
      dadosContrato,
      servico:{
        produto_id: '',
        descricao: '',
        formapagto_id: '',
        forma_pagto: '',
        valor: '',
        num_parcelas: '',
        contfin_id: '',
        conta_fin: '',
        venc_prim_parcela: '',
        recorrencia: false,
        parcelado: false,
        recorrenciaACada: 1,
        tipoRecorrencia: 'M',
        qtdRecorrencia: 1,
        gerar_nfse: false,
        index: ''
      },
      alteraServico: false,
      conta: {
        descricao: "",
        tipo: "A RECEBER",
        valortotal: "",
        numparcela: 1,
        item_id: '',
        tipo_item: 'ITEMRECDESP',
        qtd_parcelas: 1,
        gerar_nfse: false,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            valorparcela: "",
            formapagto_id: "",
            documento: "",
            num: "",
            contfin_id: "",
            parcela_editada: false,
            index: 1
          }
        ]
      },
    }))
  }

  const removerServico = (row) => {
    let {dadosContrato} = state

    dadosContrato.produtos.splice(row.index, 1)

    setState(prevState => ({...prevState,
      dadosContrato
    }))
  }

  const editarServico = (index) => {
    const {dadosContrato} = state
    
    let conta = {
      descricao: "",
      tipo: "A RECEBER",
      valortotal: dadosContrato.produtos[index].valor,
      numparcela: dadosContrato.produtos[index].parcelas.length,
      item_id: '',
      tipo_item: 'ITEMRECDESP',
      qtd_parcelas: dadosContrato.produtos[index].parcelas.length,
      gerar_nfse: false,
      parcelas: dadosContrato.produtos[index].parcelas
    }

    setState(prevState => ({...prevState,
      servico: dadosContrato.produtos[index],
      alteraServico: true,
      conta
    }))
    
  }

  const consultaProdutos = async (unidade_id) => {
    try {
      const { data: produtosAux } = await axios.get(`${window._env_.REACT_APP_API_URL}/produtos/byUnidade/${unidade_id}`, getToken())
      
      const produtos = []
      
      produtosAux.forEach(produto => {
        produtos.push({
          id: produto.id,
          mid_caminho: produto.midia ? produto.midia.mid_caminho : '',
          descricao: produto.descricao,
          unidademedida: produto.unidademedida,
          subgrupdescricao: produto.subgrupoproduto.subgrupdescricao,
          subgrupid: produto.subgrupoproduto.subgrupid,
          grupdescricao: produto.subgrupoproduto.grupoproduto.grupdescricao,
          visibilidade: produto.visibilidade,
          preco: produto.preco,
          ativo: produto.ativo
        })
      })

      setState(prevState => ({...prevState,
        produtos: {
          list: produtos
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldBonusPontualidade = () => {
    let { dadosContrato } = state

    dadosContrato.bonus_pont = !dadosContrato.bonus_pont

    setState((prevState) => ({
      ...prevState,
      dadosContrato
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))
      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'aprovacao-pre-cadastro-cliente')

      setState(prevState => ({
        ...prevState,
        permissoes
      }))

      await consultarDados()

      try {
        const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())
  
        setState(prevState => ({...prevState,
          formasPagamento: {
            list: formasPagamento.filter(param => param.ativo === true)
          }
        }))
        
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
      
      setState(prevState => ({...prevState,
        loading: false,
        dadosContrato: {
          codigo: '',
          data_ini_vig: '',
          data_fim_vig: '',
          unidade_tempo: '',
          tempo_contrato: '',
          unidade_id: '',
          cliente_id: '',
          produtos: [],
          template_versao_id: "",
          assinar_contrato: false,
          cobrar_multa: false,
          tipo_multa: '',
          valor_multa: '0,00',
          agrupar_vencidas: false
        },
        servico:{
          produto_id: '',
          descricao: '',
          formapagto_id: '',
          forma_pagto: '',
          valor: '',
          num_parcelas: '',
          contfin_id: '',
          conta_fin: '',
          venc_prim_parcela: '',
          venc_prim_parcela_sem_form: '',
          gerar_nfse: false,
          index: '',
          recorrencia: false,
          parcelado: false,
          recorrenciaACada: 1,
          tipoRecorrencia: 'M',
          qtdRecorrencia: 1,
        },
        conta: {
          descricao: "",
          tipo: "A RECEBER",
          valortotal: "",
          numparcela: 1,
          item_id: '',
          tipo_item: 'ITEMRECDESP',
          qtd_parcelas: 1,
          gerar_nfse: false,
          parcelas: [
            {
              baixado: "",
              datavencimento: "",
              valorparcela: "",
              formapagto_id: "",
              documento: "",
              num: "",
              contfin_id: "",
              parcela_editada: false,
              index: 1
            }
          ]
        }
      }))
    };
    
    fetchData();
  }, []);

  let {pessoa, tab, uploadedFiles, dadosContrato, template_documentos, servico, produtos, formasPagamento, contasFinanceira, 
    possui_emissao_nfse, conta, produtoSelecionado, alteraServico, cabecalhoTabela, acoesTabela, permissoes} = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Dados do pessoa...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={12} xs={12} sm={12}>
                <h1 className="titulo">Aprovação de Pré Cadastro</h1>
              </Grid>
            </Grid>
            <AppBar position="static" style={{marginTop: 10}}>
              <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Dados da Pessoa" {...a11yProps(0)} />
                <Tab label="Fotos de Comprovação" {...a11yProps(1)} />
                <Tab label="Finalização do Contrato" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            {pessoa.tipoPessoa === 'FISICA' &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10" id='view'>
                  <Grid item md={5} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={pessoa.pessoaFisica.nome + ' ' + pessoa.pessoaFisica.sobrenome} InputLabelProps={{ shrink: true }}  disabled />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={pessoa.pessoaFisica.cpf} InputLabelProps={{ shrink: true }}  disabled />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={pessoa.pessoaFisica.dataNascimento} InputLabelProps={{ shrink: true }}  disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Situação Pré Cadastro" value={formatarStatus(pessoa.pre_contrato.status_atual.estado_contrato)} InputLabelProps={{ shrink: true }}  disabled />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {pessoa.tipoPessoa === 'JURIDICA' &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10" id='view'>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={pessoa.pessoaJuridica.razaoSocial} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantansia" value={pessoa.pessoaJuridica.nomeFantasia} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={pessoa.pessoaJuridica.cnpj || ''} disabled InputLabelProps={{ shrink: true }}/>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={pessoa.pessoaJuridica.inscricaoEstadual || ''} disabled InputLabelProps={{ shrink: true }}/>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data Fundação" value={pessoa.pessoaJuridica.dataFundacao || ''} disabled InputLabelProps={{ shrink: true }}/>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            <hr style={{marginTop: 10}}/>
            <TabPanel value={tab} index={0}>
              {pessoa.dadosComplementaresPF &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h5 className="titulo">Dados Complementares: </h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10" id='view'>
                    {state.dadosComplementaresPF.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                      if(value.column_name === 'como_conheceu_id'){
                        return (
                          <Grid key={value.dtd_identifier} item md={3} sm={6} xs={12}>
                            <TextField 
                              fullWidth 
                              size="small" 
                              variant="outlined" 
                              className="input" 
                              label={value.column_comment} 
                              value={
                                pessoa.dadosComplementaresPF.como_conheceu ? pessoa.dadosComplementaresPF.como_conheceu.descricao : 'Não Informado'
                              } 
                              disabled 
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        )
                      }else if(value.column_name === 'doc_rg_frente' || value.column_name === 'doc_rg_verso' || value.column_name === 'doc_cnh_frente' ||
                        value.column_name === 'doc_cnh_verso' || value.column_name === 'doc_comprovante_renda' || value.column_name === 'doc_comprovante_endereco'){
                          return (
                            <Grid key={value.dtd_identifier} item md={2} sm={6} xs={12} style={{position: 'relative'}}>
                              <span>{value.column_comment}</span>
                              {pessoa.dadosComplementaresPF[`${value.column_name}_obj`] &&
                                <React.Fragment>
                                  <Tooltip title='Visualizar' aria-label="add">
                                    <VisibilityIcon color="warning" style={{position: 'absolute', top: 30, right: 10, cursor: 'pointer', fontSize: 32, zIndex: 999}} onClick={() => openDocumento(`${window._env_.REACT_APP_API_URL}/static/${pessoa.dadosComplementaresPF[`${value.column_name}_obj`].key}`, value.column_comment)}/>
                                  </Tooltip>
                                  <ImagesProvider
                                    options={{ maxImagesCount: 1 }}
                                    urls={[`${window._env_.REACT_APP_API_URL}/static/${pessoa.dadosComplementaresPF[`${value.column_name}_obj`].key}`]}
                                  >
                                    <ThumbnailOptionsProvider
                                      defaults={{
                                        size: "small"
                                      }}
                                    >
                                      <Thumbnails />
                                    </ThumbnailOptionsProvider>
                                  </ImagesProvider>
                                </React.Fragment>
                              }
                            </Grid>
                          )
                      }else{
                        return (
                          <Grid key={value.dtd_identifier} item md={3} sm={6} xs={12}>
                            <TextField 
                              fullWidth 
                              size="small" 
                              variant="outlined" 
                              className="input" 
                              label={value.column_comment} 
                              value={
                                pessoa.dadosComplementaresPF[value.column_name] 
                                ? (value.data_type === 'date' ? moment(pessoa.dadosComplementaresPF[value.column_name]).format('DD/MM/YYYY') : pessoa.dadosComplementaresPF[value.column_name]) 
                                : 'Não informado'
                              } 
                              disabled 
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        )  
                      }
                    })}
                  </Grid>
                </React.Fragment>
              }
              <hr style={{marginTop: 10}}/>
              {pessoa.enderecos.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h5 className="titulo">Endereços do cliente: </h5>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_10">
                    <Grid item md={12}>
                      <Tabela 
                        headCell={state.cabecalhoTabelaEndereco} 
                        rows={pessoa.enderecos.map(endereco => {
                          return {
                            cep: endereco.cep,
                            endereco: `${endereco.rua}, Nº ${endereco.numero}${endereco.complemento ? ` ${endereco.complemento}` : ''}, ${endereco.bairro}`,
                            cidade: endereco.cidade,
                            estado: endereco.estado,
                            principal: endereco.principal,
                            tipoEndereco: endereco.tipoEndereco,
                            index: endereco.index
                          }
                        })} 
                        acoes={state.acoesTabelaEndereco}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {pessoa.contatos.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h5 className="titulo">Telefones do cliente: </h5>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_10">
                    <Grid item md={12}>
                      <Tabela 
                        headCell={state.cabecalhoTabelaTelefone} 
                        rows={pessoa.contatos.map(contato => {
                          return {
                            numero: formataTelefone(contato.numero),
                            tipoContato: contato.tipoContato,
                            principal: contato.principal,
                            index: contato.index
                          }
                        })} 
                        acoes={state.acoesTabelaEndereco}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {pessoa.emails.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h5 className="titulo">E-mails do cliente: </h5>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_10">
                    <Grid item md={12}>
                      <Tabela 
                        headCell={state.cabecalhoTabelaEmail} 
                        rows={pessoa.emails.map(email => {
                          return {
                            email: email.email,
                            principal: email.principal,
                            index: email.index
                          }
                        })} 
                        acoes={state.acoesTabelaEndereco}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {(pessoa.pre_contrato.status_atual.estado_contrato !== "REPROVADO" 
              && pessoa.pre_contrato.status_atual.estado_contrato !== "APROVADO"
              && permissoes.findIndex(param => param.tela.slug === "dados_cliente") !== -1) &&
                <Grid container spacing={1} direction="row" style={{marginTop: 10}}>
                  <Grid item md={8} xs={12} sm={6}></Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button color='secondary' fullWidth onClick={() => reprovarContrato()} variant="contained" size="small" endIcon={<CloseIcon/>}>Reprovar Cadastro</Button>
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button color='primary' fullWidth onClick={() => aprovarDados()} variant="contained" size="small" endIcon={<SaveIcon/>}>Aprovar Dados</Button>
                  </Grid>
                </Grid>
              }
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container direction="row" spacing={1} style={{marginTop: 10}}>
                <Grid item md={2} xs={12} sm={3}>
                  <h5 className='titulo'>Imagens:</h5>
                </Grid>
                {(pessoa.pre_contrato.status_atual.estado_contrato !== "REPROVADO" && pessoa.pre_contrato.status_atual.estado_contrato !== "APROVADO") &&
                  <Grid item md={10} xs={12} sm={9}>
                    <UploadImage onUpload={e => handleUploadImage(e)} />
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1}>
                {uploadedFiles.map((img, key) => {
                  return (
                    <Grid key={key} item md={2} xs={12} sm={4} style={{textAlign: 'right', marginTop: 10}}>
                      <div style={{position: 'relative'}}>
                        <ImagesProvider
                          options={{ maxImagesCount: 1 }}
                          urls={[img.preview]}
                        >
                          <ThumbnailOptionsProvider
                            defaults={{
                              size: "medium",
                              shadow: false, 
                              border: false
                            }}
                          >
                            <Thumbnails />
                          </ThumbnailOptionsProvider>
                        </ImagesProvider>
                        <Tooltip title='Excluir Midia' aria-label="add">
                          <span className='excluir_img' onClick={e => removerImagem(img)}>EXCLUIR</span> 
                        </Tooltip>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
              {(pessoa.pre_contrato.status_atual.estado_contrato !== "REPROVADO" 
              && pessoa.pre_contrato.status_atual.estado_contrato !== "APROVADO"
              && permissoes.findIndex(param => param.tela.slug === "visita_cliente") !== -1) &&
                <Grid container spacing={1} direction="row" style={{marginTop: 10}}>
                  <Grid item md={8} xs={12} sm={6}></Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button color='secondary' fullWidth onClick={() => reprovarContrato()} variant="contained" size="small" endIcon={<CloseIcon/>}>Reprovar Cadastro</Button>
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button color='primary' fullWidth onClick={() => aprovarDados()} variant="contained" size="small" endIcon={<SaveIcon/>}>Aprovar Dados</Button>
                  </Grid>
                </Grid>
              }
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Grid container direction="row" spacing={1} style={{marginTop: 10}}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className='titulo'>Dados do Contrato:</h5>
                </Grid>
              </Grid>
              {(pessoa.pre_contrato.status_atual.estado_contrato !== "REPROVADO" && pessoa.pre_contrato.status_atual.estado_contrato !== "APROVADO") &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} style={{marginTop: 10}}>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField 
                        className="input" 
                        label="Codigo" 
                        variant="outlined" 
                        size="small"
                        name="codigo" 
                        value={dadosContrato.codigo} 
                        onChange={(e) => updateFieldContrato(e)} 
                        InputLabelProps={{ shrink: true }} 
                      />
                    </Grid>
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField type="date"
                        className="input"
                        label="Data Inicio Vigência*"
                        variant="outlined"
                        name="data_ini_vig"
                        value={dadosContrato.data_ini_vig}
                        onChange={(e) => updateFieldContrato(e)}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Unidade Tempo Duração"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="unidade_tempo"
                        value={dadosContrato.unidade_tempo}
                        disabled={dadosContrato.data_ini_vig === ''}
                        onChangeCapture={(e) => updateFieldContrato(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=''> Selecionar Unidade Tempo </option>
                        <option value='DIA'> Dia </option>
                        <option value='MÊS'> Mês </option>
                        {/* <option value='SEMESTRE'> Semestre </option> */}
                        <option value='ANO'> Ano </option>
                        
                      </TextField>
                    </Grid>
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField 
                        className="input" 
                        label="Tempo Contrato" 
                        type="number"
                        variant="outlined" 
                        size="small" 
                        name="tempo_contrato" 
                        value={dadosContrato.tempo_contrato} 
                        disabled={dadosContrato.unidade_tempo === ''}
                        onChange={(e) => updateFieldContrato(e)} 
                        InputLabelProps={{ shrink: true }} 
                      />
                    </Grid>
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField type="date"
                        className="input"
                        label="Data Fim Vigência"
                        variant="outlined"
                        name="data_fim_vig"
                        value={dadosContrato.data_fim_vig}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={2} xs={12} sm={2}>
                      <FormControl component="fieldset" style={{marginTop: -10}}>
                        <FormLabel component="legend">Agrupar Contas Vencidas?</FormLabel>
                        <FormControlLabel className="mg_left_10"
                          control={
                            <Switch
                              name="agrupar_vencidas"
                              color="primary"
                              checked={dadosContrato.agrupar_vencidas}
                            />
                          }
                          size="small"
                          label={dadosContrato.agrupar_vencidas ? 'Sim' : 'Não'}
                          name="agrupar_vencidas"
                          onChange={(e) => updateFieldAgruparVencidas(e)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12} sm={2}>
                      <FormControl component="fieldset" style={{marginTop: -10}}>
                        <FormLabel component="legend">Cobrar Multa?</FormLabel>
                        <FormControlLabel className="mg_left_10"
                          control={
                            <Switch
                              name="Ativo"
                              color="primary"
                              checked={dadosContrato.cobrar_multa}
                            />
                          }
                          size="small"
                          label={dadosContrato.cobrar_multa ? 'Sim' : 'Não'}
                          name="cobrar_multa"
                          onChange={(e) => updateFieldCobrarMulta(e)}
                        />
                      </FormControl>
                    </Grid>
                    {dadosContrato.cobrar_multa &&
                      <React.Fragment>
                        <Grid item md={3} xs={12} sm={6}>
                          <TextField
                            id="standard-select-currency"
                            select
                            label="Tipo da Multa"
                            variant="outlined"
                            className="input"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="tipo_multa"
                            value={dadosContrato.tipo_multa}
                            onChangeCapture={(e) => updateFieldContrato(e)}
                            InputLabelProps={{ shrink: true }}
                          >
                            <option value=''> Selecionar Tipo da Multa </option>
                            <option value='FIXO'> Valor Fixo </option>
                            <option value='PORCENTAGEM'> Valor em Porcentagem </option>
                          </TextField>
                        </Grid>
                        <Grid item md={2} xs={12} sm={6}>
                          <TextField 
                            className="input" 
                            label="Valor da Multa"
                            variant="outlined" 
                            size="small" 
                            name="valor_multa" 
                            value={dadosContrato.valor_multa} 
                            disabled={dadosContrato.tipo_multa === ''}
                            onChange={(e) => updateFieldContrato(e)} 
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputComponent: CurrencyInput }}
                          />
                        </Grid>
                      </React.Fragment>
                    }
                    <Grid item md={2} xs={12} sm={2}>
                      <FormControl component="fieldset" style={{marginTop: -10}}>
                        <FormLabel component="legend">Bônus por Pontualidade?</FormLabel>
                        <FormControlLabel className="mg_left_10"
                          control={
                            <Switch
                              name="bonus_pont"
                              color="primary"
                              checked={dadosContrato.bonus_pont}
                            />
                          }
                          size="small"
                          label={dadosContrato.bonus_pont ? 'Sim' : 'Não'}
                          name="bonus_pont"
                          onChange={(e) => updateFieldBonusPontualidade(e)}
                        />
                      </FormControl>
                    </Grid>
                    {dadosContrato.bonus_pont &&
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField 
                          className="input" 
                          label="Porc. do Bônus (%)"
                          variant="outlined" 
                          size="small" 
                          name="porc_bonus_pont" 
                          value={dadosContrato.porc_bonus_pont} 
                          onChange={(e) => updateFieldContrato(e)} 
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: CurrencyInput }}
                        />
                      </Grid>
                    }
                  </Grid>
                  {template_documentos.length > 0 &&
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Selecionar Documento"
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{ native: true }}
                          name="template_versao_id"
                          value={dadosContrato.template_versao_id}
                          onChangeCapture={(e) => updateFieldContrato(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value=""> Não Emitir Documento</option>
                          {template_documentos.map(documento => {
                            return (
                              <option key={documento.versao_id} value={documento.versao_id}> {documento.titulo} - Versão: {documento.versao} </option>
                            )
                          })}
                        </TextField>
                      </Grid>
                      {dadosContrato.template_versao_id !== "" &&
                        <Grid item md={2} xs={12} sm={1}>
                          <FormControl component="fieldset" style={{marginLeft: 20}}>
                            <FormLabel component="legend">Assinar?</FormLabel>
                            <FormControlLabel
                              control={
                                <Switch
                                  name="Ativo"
                                  color="primary"
                                  checked={dadosContrato.assinar_contrato}
                                />
                              }
                              size="small"
                              label={dadosContrato.assinar_contrato ? 'Sim' : 'Não'}
                              name="assinar_contrato"
                              onChange={(e) => updateFieldAssinar(e)}
                            />
                          </FormControl>
                        </Grid>
                      }
                    </Grid>
                  }
                  <hr/>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={2} sm={12}>
                      <h5 className='titulo'>Informar Servicos:</h5>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={2} xs={2} sm={2}>
                      <Button
                        fullWidth 
                        color={servico.parcelado ? 'primary' : 'inherit'} 
                        variant="contained" 
                        size="small" 
                        endIcon={servico.parcelado ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />} 
                        onClick={e => updateParcelado()}
                      >
                        Parcelamento
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={2} sm={2}>
                      <Button 
                        fullWidth 
                        color={servico.recorrencia ? 'secondary' : 'inherit'} 
                        variant="contained" 
                        size="small" 
                        endIcon={servico.recorrencia ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />}
                        onClick={e => updateRecorrencia()}
                      >
                        Recorrência
                      </Button>
                    </Grid>
                  </Grid>
                  {servico.recorrencia && 
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={4} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Serviço"
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{ native: true }}
                          name="produto_id"
                          value={servico.produto_id}
                          onChangeCapture={(e) => updateServico(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value=""> Selecionar Produto</option>
                          {produtos.list.map(produto => {
                            return (
                              <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                            )
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={1} xs={12} sm={6}>
                        <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={servico.valor} onChange={(e) => updateServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField 
                          type='number'
                          className="input" 
                          label="Repetir a cada*" 
                          variant="outlined" 
                          name="recorrenciaACada" 
                          size="small"
                          value={servico.recorrenciaACada} 
                          onChange={(e) => updateServico(e)} 
                          InputLabelProps={{ shrink: true }}/>
                      </Grid>
                      <Grid item md={1} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label=""
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="tipoRecorrencia"
                          value={servico.tipoRecorrencia}
                          onChangeCapture={(e) => updateServico(e)}
                        >
                          <option value='d'> Dia </option>
                          <option value='w'> Semana </option>
                          <option value='M'> Mês </option>
                          <option value='y'> Ano </option>
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField 
                          type='number'
                          className="input" 
                          label="Num. de Ocorrências*" 
                          variant="outlined" 
                          name="qtdRecorrencia" 
                          size="small"
                          value={servico.qtdRecorrencia} 
                          onChange={(e) => updateServico(e)} 
                          InputLabelProps={{ shrink: true }}/>
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField className="input" type='date' label="Venc. Prim. Recor.*" variant="outlined" size="small" name="venc_prim_parcela" value={servico.venc_prim_parcela} onChange={(e) => updateServico(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{ native: true }}
                          name="formapagto_id"
                          value={servico.formapagto_id}
                          label="Forma de Pgto.*"
                          onChangeCapture={(e) => updateServico(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value="">Selecionar Forma de Pgto.</option>
                          {formasPagamento.list.map(forma => {
                            return (
                              <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                            )
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="contfin_id"
                          value={servico.contfin_id}
                          label="Contas Bancárias.*"
                          onChangeCapture={(e) => updateServico(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value="">--Escolha--</option>
                          {contasFinanceira.list.map(conta => {
                            return (
                              <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                            )
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField 
                          className="input" 
                          label="Totalizando*" 
                          variant="outlined" 
                          name="qtdRecorrencia" 
                          size="small"
                          value={servico.valor ? real(parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")) * servico.qtdRecorrencia) : real(0)} 
                          InputLabelProps={{ shrink: true }}
                          disabled/>
                      </Grid>
                      {possui_emissao_nfse &&
                        <Grid item md={1} xs={12} sm={1}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Gerar NFSE?</FormLabel>
                            <FormControlLabel className="mg_left_10"
                              control={
                                <Switch
                                  name="Ativo"
                                  color="primary"
                                  checked={servico.gerar_nfse}
                                />
                              }
                              size="small"
                              label={servico.gerar_nfse ? 'Sim' : 'Não'}
                              name="gerar_nfse"
                              onChange={(e) => updateFieldGerarNfse(e)}
                            />
                          </FormControl>
                        </Grid>
                      }
                    </Grid>
                  }
                  {servico.parcelado && 
                    <React.Fragment>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        <Grid item md={4} xs={12} sm={6}>
                          <TextField
                            id="standard-select-currency"
                            select
                            label="Serviço"
                            variant="outlined"
                            className="input"
                            fullWidth
                            size="small"
                            SelectProps={{ native: true }}
                            name="produto_id"
                            value={servico.produto_id}
                            onChangeCapture={(e) => updateServico(e)}
                            InputLabelProps={{ shrink: true }}
                          >
                            <option value=""> Selecionar Produto</option>
                            {produtos.list.map(produto => {
                              return (
                                <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                              )
                            })}
                          </TextField>
                        </Grid>
                        <Grid item md={2} xs={12} sm={6}>
                          <TextField 
                            className="input" 
                            label="Valor Total*" 
                            variant="outlined" 
                            size="small" 
                            name="valor" 
                            value={servico.valor} 
                            onChange={(e) => updateServico(e)} 
                            InputLabelProps={{ shrink: true }} 
                            InputProps={{ inputComponent: CurrencyInput }}
                            disabled={servico.produto_id === ''}
                          />
                        </Grid>
                        {possui_emissao_nfse &&
                          <Grid item md={1} xs={12} sm={1}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Gerar NFSE?</FormLabel>
                              <FormControlLabel className="mg_left_10"
                                control={
                                  <Switch
                                    name="Ativo"
                                    color="primary"
                                    checked={servico.gerar_nfse}
                                  />
                                }
                                size="small"
                                label={servico.gerar_nfse ? 'Sim' : 'Não'}
                                name="gerar_nfse"
                                onChange={(e) => updateFieldGerarNfse(e)}
                              />
                            </FormControl>
                          </Grid>
                        }
                      </Grid>
                      {servico.valor !== '' &&
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          <Grid item md={12} xs={12} sm={12}>
                            <div className='scroll'>
                              <table className="lista sempadding borderTable">
                                <thead>
                                  <tr className="titulos acompanha">
                                    <th>Parcelas</th>
                                    <th>Valor*</th>
                                    <th>Vencimento*</th>
                                    <th>Forma de Pagamento*</th>
                                    <th>Contas Bancárias</th>
                                    <th>Documento</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                                    return (
                                      <tr key={parcela.index}>
                                        <td>
                                          <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                                          <span className="separacaoParcelas">/</span>
                                          <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }}/>
                                        </td>
                                        <td>
                                          <TextField 
                                            className="inputValor" 
                                            variant="outlined" 
                                            size="small" 
                                            name="valorparcela" 
                                            value={parcela.valorparcela} 
                                            onBlur={(e) => recalcularParcela(e, parcela.index)} 
                                            InputLabelProps={{ shrink: true }} 
                                            InputProps={{ inputComponent: CurrencyInput }}
                                          />
                                        </td>
                                        <td>
                                          <TextField 
                                            type="date" 
                                            className="inputVencimento" 
                                            variant="outlined" 
                                            size="small" 
                                            name="datavencimento" 
                                            value={parcela.datavencimento} 
                                            onChange={(e) => updateFieldParcela(e, parcela.index)} 
                                            onBlur={(e) => verificaDataParcela(e, parcela.index)} 
                                            InputLabelProps={{ shrink: true }}
                                          />
                                        </td>
                                        <td>
                                          <TextField
                                            id="standard-select-currency"
                                            select
                                            variant="outlined"
                                            className="inputFormPagamento"
                                            fullWidth
                                            size="small"
                                            SelectProps={{
                                              native: true,
                                            }}
                                            name="formapagto_id"
                                            value={parcela.formapagto_id}
                                            onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                            onBlur={(e) => marcaParcelas(e, parcela.index)}
                                            InputLabelProps={{ shrink: true }}
                                          >
                                            <option value="">--Escolha--</option>
                                            {formasPagamento.list.map(forma => {
                                              return (
                                                <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                              )
                                            })}
                                          </TextField>
                                        </td>
                                        <td>
                                          <TextField
                                            id="standard-select-currency"
                                            select
                                            variant="outlined"
                                            className="inputFormPagamento"
                                            fullWidth
                                            size="small"
                                            SelectProps={{
                                              native: true,
                                            }}
                                            name="contfin_id"
                                            value={parcela.contfin_id}
                                            onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                            onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                                            InputLabelProps={{ shrink: true }}
                                          >
                                            <option value="">--Escolha--</option>
                                            {contasFinanceira.list.filter(param => param.tipo_conta === parcela.habilita).map(conta => {
                                              return (
                                                <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                              )
                                            })}
                                          </TextField>
                                        </td>
                                        <td>
                                          <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </Grid>
                        </Grid>
                      }
                    </React.Fragment>
                  }
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    {produtoSelecionado.preco &&
                      <React.Fragment>
                        <Grid item md={2} xs={12} sm={3}>
                          <p style={{marginBottom: '0px'}}>Valores Sugeridos da Tabela: </p>
                        </Grid>
                        <Grid item md={2} xs={12} sm={2}>
                          <TextField className="input" label="Valor Mínimo*" variant="outlined" size="small" name="valor_minimo" value={` R$ ${real(produtoSelecionado.preco.valor_minimo)}`} InputLabelProps={{ shrink: true }} disabled/>
                        </Grid>
                        <Grid item md={2} xs={12} sm={2}>
                          <TextField className="input" label="Valor Máximo*" variant="outlined" size="small" name="valor_maximo" value={` R$ ${real(produtoSelecionado.preco.valor_maximo)}`} InputLabelProps={{ shrink: true }} disabled/>
                        </Grid>
                      </React.Fragment>
                    } 
                    {!produtoSelecionado.preco &&
                      <Grid item md={6} xs={12} sm={7}></Grid>
                    }
                    <Grid item md={4} xs={12} sm={1}></Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addServico(e)}>{alteraServico ? 'Alterar' : 'Adicionar'}</Button>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                    <Table
                      remove={e => removerServico(e)}
                      editar={e => editarServico(e)}
                      headCell={cabecalhoTabela}
                      rows={dadosContrato.produtos.map(value => {
                        return {
                          produto_id: value.produto_id,
                          descricao: value.descricao,
                          formapagto_id: value.formapagto_id,
                          forma_pagto: value.forma_pagto,
                          valor_contrato: 'R$ '  + (value.recorrencia ? real(parseFloat(value.valor.replaceAll(".", "").replace(",", ".")) * parseInt(value.qtdRecorrencia)) : value.valor),
                          parcelas: value.descricao_parcelamento,
                          venc_prim_parcela: moment(value.venc_prim_parcela).format('DD/MM/YYYY'),
                          conta_fin: value.conta_fin,
                          contfin_id: value.contfin_id,
                          parcelado: value.parcelado,
                          recorrencia: value.recorrencia,
                          gerar_nfse: value.gerar_nfse,
                          index: value.index
                        }
                      })}
                      acoes={acoesTabela} />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {(pessoa.pre_contrato.status_atual.estado_contrato !== "REPROVADO" 
              && pessoa.pre_contrato.status_atual.estado_contrato !== "APROVADO"
              && permissoes.findIndex(param => param.tela.slug === "finalizacao_contrato") !== -1) &&
                <Grid container spacing={1} direction="row" style={{marginTop: 10}}>
                  <Grid item md={8} xs={12} sm={6}></Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button color='secondary' fullWidth onClick={() => reprovarContrato()} variant="contained" size="small" endIcon={<CloseIcon/>}>Reprovar Cadastro</Button>
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button color='primary' fullWidth onClick={() => aprovarDados()} variant="contained" size="small" endIcon={<SaveIcon/>}>Aprovar Dados</Button>
                  </Grid>
                </Grid>
              }
            </TabPanel>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalVisualizarDocumento 
        open={state.openModalVisualizarDocumento}
        handleClose={e => handleModalVisualizarDocumento()}
        documento={state.documento}
      />
    </React.Fragment>
  )

}

export default ViewCliente