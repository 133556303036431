import React, { useState, useEffect } from 'react';
import calculaIdade from './../../services/calculaIdade'
import './Cliente.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import moment from 'moment';
import axios from 'axios'
import TabViews from './TabViews'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import { useParams, useNavigate } from 'react-router-dom';
import initialState from './initialState/view';
import getToken from './../../services/getToken'

const cpf = require('node-cpf-cnpj');

const ViewCliente = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { clienteId, tab } = useParams();

  const criaUrl = () =>{
    const filtro = JSON.parse(localStorage.getItem('filtro_pessoas'))

    let url = `/cliente?limit=${filtro.limit}&offset=${filtro.offset}`

    if(filtro.filtrar_por !== ''){
      url += `&filtrar_por=${filtro.filtrar_por.replaceAll(' ', '%')}&value=${filtro.value.replaceAll(' ', '%')}`
    }

    if(filtro.nome !== ''){
      url += `&nome=${filtro.nome.replaceAll(' ', '%')}`
    }

    if(filtro.cpf_cnpj !== ''){
      url += `&cpf_cnpj=${filtro.cpf_cnpj.replaceAll(' ', '')}`
    }

    if(filtro.telefone !== ''){
      url += `&telefone=${filtro.telefone.replaceAll(' ', '')}`
    }

    if(filtro.ativo !== ''){
      url += `&ativo=${filtro.ativo.replaceAll(' ', '')}`
    }

    return url
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      let url = criaUrl()

      navigate(url);
    }, timeout)
  }

  const recarregarPagina = () => {
    if(tab){
      navigate(`/cliente/view/${state.filtro.matricula}/${tab}`);
      window.location.reload()
    }else{
      navigate(`/cliente/view/${state.filtro.matricula}`);
      window.location.reload()
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem('pessoa_cadastrada');
      localStorage.removeItem('ultima_tela_cliente');

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'cliente')[0]
    
      setState(prevState => ({...prevState,
        permissoes
      }))

      if (!permissoes.visualizar) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }))
        backPage()
      }

      let permissoesContrato = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contrato')[0]

      if(permissoesContrato){
        setState(prevState => ({...prevState,
          permissoesContrato
        }))
      }

      if (clienteId) {
        try {
          const { data: pessoa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/${clienteId}`, getToken())
                  
          const { data: dadosComplementaresPF } = await axios.get(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPF/list/${pessoa.unidade_id}`, getToken())

          let enderecos = []
          let contatos = []
          let emails = []

          const auxEnderecos = pessoa.enderecos

          for (let i = 0; i < auxEnderecos.length; i++) {
            enderecos.push({
              id: auxEnderecos[i].id,
              cep_id: auxEnderecos[i].cep_id,
              rua: auxEnderecos[i].logradouro,
              complemento: auxEnderecos[i].complemento,
              numero: auxEnderecos[i].numero,
              bairro: auxEnderecos[i].bairro,
              cidade: auxEnderecos[i].cidade,
              estado: auxEnderecos[i].estado,
              cep: auxEnderecos[i].num_cep,
              principal: auxEnderecos[i].principal,
              tipoEndereco: auxEnderecos[i].tipo,
              index: i + 1
            })
          }

          const auxContatos = pessoa.telefones

          for (let i = 0; i < auxContatos.length; i++) {
            contatos.push({
              id: auxContatos[i].id,
              numero: auxContatos[i].numero,
              tipoContato: auxContatos[i].tipo,
              principal: auxContatos[i].principal,
              index: i + 1
            })
          }

          const auxEmails = pessoa.emails

          for (let i = 0; i < auxEmails.length; i++) {
            emails.push({
              id: auxEmails[i].id,
              email: auxEmails[i].email,
              principal: auxEmails[i].principal,
              index: i + 1
            })
          }
          
          let pessoaFisica = null
          let pessoaJuridica = null
          
          if(pessoa.tipo === 'FISICA'){
            const idade = calculaIdade(moment(pessoa.fisica.datanascimento).format('Y-MM-DD'))
            
            pessoaFisica = {
              id: pessoa.fisica.id,
              nome: pessoa.fisica.nome,
              sobrenome: pessoa.fisica.sobrenome,
              cpf: cpf.format(pessoa.fisica.cpf),
              rg: pessoa.fisica.rg,
              dataNascimento: moment(pessoa.fisica.datanascimento).format('Y-MM-DD'),
              idade: idade,
              maiorIdade: idade < 18 ? false : true
            }
          }else{
            pessoaJuridica = {
              id: pessoa.juridica.id,
              razaoSocial:pessoa.juridica.razaosocial,
              nomeFantasia:pessoa.juridica.nomefantasia,
              cnpj:pessoa.juridica.cnpj,
              inscricaoEstadual:pessoa.juridica.inscricaoestadual,
              dataFundacao:pessoa.juridica.datafundacao
            }
          }

          const flags = pessoa.flags.map(value => value.flag)

          const dados = {
            _id: pessoa.id,
            unidade_id: pessoa.unidade_id,
            flags,
            tipoPessoa: pessoa.tipo,
            codigo: pessoa.cliente ? pessoa.cliente.codigo : '',
            pessoaFisica,
            pessoaJuridica,
            enderecos,
            emails,
            contatos,
            contrato: pessoa.cliente ? pessoa.cliente.contrato : null,
            cliente: pessoa.cliente ? pessoa.cliente : null,
            dadosComplementaresPF: pessoa.tipo === 'FISICA' ? (pessoa.fisica.dados_complementares ? pessoa.fisica.dados_complementares : null) : null
          }

          setState(prevState => ({...prevState,
            pessoa: dados,
            dadosComplementaresPF
          }))

          if(flags.includes('CLIENTE')){
            const responsaveis = []
    
            if(pessoa.cliente.contrato){
              if (pessoa.cliente.contrato.resp_fin.length > 0) {
      
                for (const responsavel of pessoa.cliente.contrato.resp_fin) {
                  let enderecos = []
                  let contatos = []
                  let emails = []
      
                  const auxEnderecos = responsavel.pessoa.enderecos
      
                  for (let i = 0; i < auxEnderecos.length; i++) {
                    enderecos.push({
                      id: auxEnderecos[i].id,
                      cep_id: auxEnderecos[i].cep_id,
                      rua: auxEnderecos[i].logradouro,
                      complemento: auxEnderecos[i].complemento,
                      numero: auxEnderecos[i].numero,
                      bairro: auxEnderecos[i].bairro,
                      cidade: auxEnderecos[i].cidade,
                      estado: auxEnderecos[i].estado,
                      cep: auxEnderecos[i].num_cep,
                      principal: auxEnderecos[i].principal,
                      tipoEndereco: auxEnderecos[i].tipo,
                      index: i + 1
                    })
                  }
      
                  const auxContatos = responsavel.pessoa.telefones
      
                  for (let i = 0; i < auxContatos.length; i++) {
                    contatos.push({
                      id: auxContatos[i].id,
                      numero: auxContatos[i].numero,
                      tipoContato: auxContatos[i].tipo,
                      principal: auxContatos[i].principal,
                      index: i + 1
                    })
                  }
      
                  const auxEmails = responsavel.pessoa.emails
      
                  for (let i = 0; i < auxEmails.length; i++) {
                    emails.push({
                      id: auxEmails[i].id,
                      email: auxEmails[i].email,
                      principal: auxEmails[i].principal,
                      index: i + 1
                    })
                  }
      
                  if(responsavel.pessoa.tipo === 'FISICA'){
                    responsaveis.push({
                      pessoa_tipo: 'FISICA',
                      pessoa_id: responsavel.pessoa.id,
                      pessoaFisica: {
                        id: responsavel.pessoa.fisica.id,
                        nome: responsavel.pessoa.fisica.nome,
                        sobrenome: responsavel.pessoa.fisica.sobrenome,
                        cpf: cpf.format(responsavel.pessoa.fisica.cpf),
                        rg: responsavel.pessoa.fisica.rg,
                        rg_orgao_emissor: responsavel.pessoa.fisica.rg_orgao_emissor,
                        rg_uf: responsavel.pessoa.fisica.rg_uf,
                        rg_data_emissao: responsavel.pessoa.fisica.rg_data_emissao ? moment(responsavel.pessoa.fisica.rg_data_emissao).format('DD/MM/YYYY') : '',
                        dataNascimento: moment(responsavel.pessoa.fisica.datanascimento).format('DD/MM/YYYY'),
                      },
                      pessoaJuridica: null,
                      enderecos,
                      emails,
                      contatos,
                    })
                  }else{
                    responsaveis.push({
                      pessoa_tipo: 'JURIDICA',
                      pessoa_id: responsavel.pessoa.id,
                      pessoaFisica: null,
                      pessoaJuridica:{
                        id: responsavel.pessoa.juridica.id,
                        razaoSocial:responsavel.pessoa.juridica.razaosocial,
                        nomeFantasia:responsavel.pessoa.juridica.nomefantasia,
                        cnpj:responsavel.pessoa.juridica.cnpj,
                        inscricaoEstadual:responsavel.pessoa.juridica.inscricaoestadual,
                        dataFundacao: moment(responsavel.pessoa.juridica.datafundacao).format('DD/MM/YYYY')
                      },
                      enderecos,
                      emails,
                      contatos,
                    })
                  }
                }
              }
            }
    
            setState(prevState => ({...prevState,
              responsaveis
            }))
          }
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
    
          backPage()
        }
      }

      const session = JSON.parse(localStorage.getItem('unidades_acesso'))

      setState(prevState => ({...prevState,
        logado: session.unidade,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <TabViews
                dadosCliente={state.pessoa}
                dadosContrato={state.pessoa ? (state.pessoa.cliente ? state.pessoa.cliente.contrato : null) : null}
                dadosResponsavel={state.responsaveis}
                filtro={state.filtro}
                recarregarPagina={e => recarregarPagina(e)}
                financeiros={state.financeiros.list}
                disabledButtonsTransferencia={state.disabledButtonsTransferencia}
                logado={state.logado}
                formasPagamento={state.formasPagamento}
                transferencia={state.transferencia}
                unidades={state.unidades}
                dadosComplementaresPF={state.dadosComplementaresPF}
                permissoesContrato={state.permissoesContrato}
                tab={tab}
                navigate={navigate}
              />
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )

}

export default ViewCliente