import React, { useState, useEffect } from 'react';
import './Produto.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormProduto from '../../components/forms/FormProduto'
import FormCentroCustoProduto from '../../components/forms/FormCentroCustoProduto'
import SaveIcon from '@mui/icons-material/Save'
import { uniqueId } from 'lodash'
import {filesize} from "filesize";
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import ModalConfirmação from './../../components/modals/Confirmacao'
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
  produto: {
    _id: "",
    descricao: "",
    codigo: "",
    ativo: true,
    grupoProduto: "",
    idGrupoProduto: "",
    subGrupoProduto: "",
    idSubGrupoProduto: "",
    unidadeMedida: "",
    visibilidade: "",
    observacao: "",
    embalagem: "",
    destaque: false,
    itensCombo: [],
    unidade_id : "",
    tipo: "",
    possui_financeiro: false,
    planocontas_id: "",
    planocontas_receita_id: "",
    duracao: 0,
    estoque: {
      estoqueMinimo: "",
      estoqueAtual: "",
      valorVenda: "",
      precoCusto: ""
    },
    centro_lucros: [],
    centro_custos: []
  },
  itemCombo: {
    produtoId: "",
    produtoItem: "",
    quantidade: "",
    duracao: 0,
    ambientes: "",
    principal: false,
    ativo: true
  },
  produtos: {
    list: []
  },
  grupoProdutos: {
    list: []
  },
  subGrupoProdutos: {
    list: []
  },
  subGrupoProdutosFiltrado: {
    list: []
  },
  uploadedFiles: [],
  deleteIcone: false,
  loading: true,
  mensagemLoading: 'Carregando Produto...',
  trocaIcone: false,
  update: false,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  tipo: 'UNIDADE',
  planoContas: {
    list: []
  },
  centros_lucro: {
    list: []
  },
  centros_custo: {
    list: []
  },
  centro_lucro:{
    id: '',
    centrocusto_id: '',
    centrocusto: '',
    valor_percentual: 0,
    ativo: true,
    index: ''
  },
  centro_custo: {
    id: '',
    centrocusto_id: '',
    centrocusto: '',
    valor_percentual: 0,
    ativo: true,
    index: ''
  },
  updateCentro: false,
  planocontas_anterior_id: '',
  openModalConfirmação: false,
  modalConfirmação:{
    mensagem: ''
  },
  tab: 0,
  ambientes: {
    list: []
  },
  ambientesSelecionado: [],
  openModalAjuda: false,
  markdown: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CadastroProduto = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { produtoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = async (event) => {
    const {produto, unidades, planoContas} = state

    const name = event.target.name
    const value = event.target.value

    if(name === 'unidade_id'){
      
      setState(prevState => ({...prevState,
        tipo: unidades.list.filter(param => param.id === parseInt(value))[0].tipo
      }))

      await buscarGrupoProduto(parseInt(value))
      await buscaPlanos(parseInt(value))
      await buscarAmbientes(parseInt(value))

      produto.idGrupoProduto = ''
      produto.idSubGrupoProduto = ''
      
    }

    if (name === "idGrupoProduto") {
      const subGrupoProdutos = state.subGrupoProdutos.list.filter(u => u.idGrupoProduto === parseInt(value))

      setState(prevState => ({...prevState,
        subGrupoProdutosFiltrado: {
          list: subGrupoProdutos
        }
      }))

      produto.idSubGrupoProduto = ''
    }

    if (name === "idSubGrupoProduto") {
      await buscarProdutos(parseInt(produto.unidade_id), parseInt(value))
    }

    if(name === 'planocontas_receita_id'){
    
      const plano_selecionada = planoContas.list.filter(param => param.id === parseInt(value))[0]

      if(produto.centro_lucros.length > 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:'Esse produto possui centros de custos selecionados, não é possivel alterar o plano de contas!'
          }
        }))

        return 
      }

      if(plano_selecionada.centro_custos.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:'Esse plano de contas não possui centro de lucro cadastrados!'
          }
        }))

        return 
      }

      if(plano_selecionada.centro_custos.length === 1){

        let centro_lucro = plano_selecionada.centro_custos[0]

        produto.centro_lucros.push({
          id: '',
          centrocusto_id: centro_lucro.id,
          centrocusto: centro_lucro.centro_custo.descricao,
          valor_percentual: 100,
          ativo: centro_lucro.ativo,
          index: 0
        })

        setState(prevState => ({...prevState,
          produto
        }))
      }

      setState(prevState => ({...prevState,
        centros_lucro:{
          list: plano_selecionada.centro_custos
        }
      }))
    }

    if(name === 'planocontas_id'){
    
      const plano_selecionada = planoContas.list.filter(param => param.id === parseInt(value))[0]

      if(produto.centro_custos.length > 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:'Esse produto possui centros de custos selecionados, não é possivel alterar o plano de contas!'
          }
        }))

        return 
      }

      if(plano_selecionada.centro_custos.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:'Esse plano de contas não possui centro de custo cadastrados!'
          }
        }))

        return 
      }

      if(plano_selecionada.centro_custos.length === 1){

        let centro_lucro = plano_selecionada.centro_custos[0]

        produto.centro_custos.push({
          id: '',
          centrocusto_id: centro_lucro.id,
          centrocusto: centro_lucro.centro_custo.descricao,
          valor_percentual: 100,
          ativo: centro_lucro.ativo,
          index: 0
        })

        setState(prevState => ({...prevState,
          produto
        }))
      }
      
      setState(prevState => ({...prevState,
        centros_custo:{
          list: plano_selecionada.centro_custos
        }
      }))
    }

    if(name === 'tipo' || name === 'unidadeMedida'){
      produto.duracao = 0
    }

    produto[name] = value
    setState(prevState => ({...prevState, produto }))
  }

  const buscaPlanos = async(unidade_id)=> {

    const dados = {
      unidadesnegocio: [parseInt(unidade_id)]
    }
    
    try {
  
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas/list`, dados,getToken())

      const planoContas = data.filter(param => param.ativo).map(value => {
        return {
          id: value.id,
          codigo: value.codigo,
          descricao: value.descricao,
          unidade_cadastro: value.unidade_cadastro.descricao ? (value.unidade_cadastro.numero + ' - ' + value.unidade_cadastro.descricao) : `(${value.unidade_cadastro.tipo}) - ` + (value.unidade_cadastro.pessoa.tipo === 'FISICA' ? value.unidade_cadastro.pessoa.fisica.nome : value.unidade_cadastro.pessoa.juridica.nomefantasia),
          tipo: value.totalizador ? (value.nivel === 1 ? 'Totalizador' : 'Sub-Totalizador') : (value.tipo === 'RECEITA' ? 'Receita' : 'Despesa'),
          ativo: value.ativo,
          tipo_saida_entrada: value.tipo,
          totalizador: value.totalizador,
          centro_custos: value.centro_custos
        }
      })

      setState(prevState => ({...prevState,
        planoContas: {
          list: planoContas
        }
      }))

      return planoContas

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const buscarGrupoProduto = async(unidade_id) => {
    try {
      
      const dados = {
        unidadesnegocio: [parseInt(unidade_id)]
      }

      const { data: grupoProdutos } = await axios.post(`${window._env_.REACT_APP_API_URL}/grupoProdutos/list`, dados, getToken())

      setState(prevState => ({...prevState,
        grupoProdutos: {
          list: grupoProdutos.filter(u => u.ativo === true)
        }
      }))

      const { data: subGrupoProdutosAux } = await axios.post(`${window._env_.REACT_APP_API_URL}/subGrupoProdutos/list`, dados, getToken())

      const subGrupoProdutos = []

      subGrupoProdutosAux.forEach(subGrupoProduto => {
        subGrupoProdutos.push({
          _id: subGrupoProduto.id,
          idGrupoProduto: subGrupoProduto.grupoproduto.id,
          grupoProduto: subGrupoProduto.grupoproduto.descricao,
          descricao: subGrupoProduto.descricao,
          ativo: subGrupoProduto.ativo
        })
      })

      setState(prevState => ({...prevState,
        subGrupoProdutos: {
          list: subGrupoProdutos
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage()
    }
  }

  const buscarAmbientes = async(unidade_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/ambiente/list`, dados, getToken())

      const ambientes = []

      data.filter(param => param.ativo).sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0).forEach(ambiente => {
        ambientes.push({
          id: ambiente.id,
          nome: ambiente.nome,
        })
      })

      setState(prevState => ({...prevState,
        ambientes: {
          list: ambientes
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const buscarProdutos = async(unidade_id, subgrupo_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const {data: produtosAux} = await axios.post(`${window._env_.REACT_APP_API_URL}/produtos/list`, dados, getToken())

      const produtos = []

      produtosAux.filter(param => param.unidademedida !== 'COMBO').sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).filter(param => param.ativo === true).forEach(produto => {
        let ambientes = ''

        if(produto.servico){
          for (let i = 0; i < produto.servico.ambientes_servicos.length; i++) {
            let ambiente = produto.servico.ambientes_servicos[i].ambiente

            if((i + 1) === produto.servico.ambientes_servicos.length){
              ambientes = ambientes + `e ${ambiente.nome}.`
            }else{  
              ambientes = ambientes + `${ambiente.nome}, `
            }
          }
        }
        
        produtos.push({
          _id: produto.id,
          url: produto.midia ? produto.midia.mid_caminho : '',
          descricao: produto.descricao,
          unidadeMedida: produto.unidademedida,
          subGrupoProduto: produto.subgrupoproduto.descricao,
          grupoProduto: produto.subgrupoproduto.grupoproduto.descricao,
          visibilidade: produto.visibilidade,
          duracao: produto.servico ? produto.servico.duracao : 0,
          ambientes,
          ativo: produto.ativo
        })
      })

      setState(prevState => ({...prevState,
        produtos: {
          list: produtos
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldAtivo = () => {
    const produto = { ...state.produto }
    produto.ativo = !produto.ativo
    setState(prevState => ({...prevState, produto }))
  }

  const updateFieldDestaque = () => {
    const produto = { ...state.produto }
    produto.destaque = !produto.destaque
    setState(prevState => ({...prevState, produto }))
  }

  const updateFieldItemAtivo = () => {
    const itemCombo = { ...state.itemCombo }
    itemCombo.ativo = !itemCombo.ativo
    setState(prevState => ({...prevState, itemCombo }))
  }

  const updateFieldItemPrincipal = () => {
    const itemCombo = { ...state.itemCombo }
    itemCombo.principal = !itemCombo.principal
    setState(prevState => ({...prevState, itemCombo }))
  }

  const updateFieldCombo = (event) => {
    const itemCombo = state.itemCombo

    if (event.target.name === "produtoItem") {

      const produtos = state.produtos.list

      const indexProduto = produtos.findIndex(prod => prod._id === parseInt(event.target.value))
      
      itemCombo.produtoId = parseInt(event.target.value)
      itemCombo.produtoItem = produtos[indexProduto].descricao
      itemCombo.duracao = produtos[indexProduto].duracao
      itemCombo.ambientes = produtos[indexProduto].ambientes

    } else {

      itemCombo.quantidade = parseInt(event.target.value)
    }

    setState(prevState => ({...prevState, itemCombo }))
  }

  const handleUpload = (files) => {

    setState(prevState => ({...prevState,
      uploadedFiles: []
    }))

    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }))

    concatUploadedFiles(uploadedFiles)
  }

  const concatUploadedFiles = (uploadedFiles) => {
    setState(prevState => ({...prevState,
      uploadedFiles: state.uploadedFiles.concat(uploadedFiles),
      trocaIcone: true
    }))
  }

  const validateItemCombo = () => {
    const itemCombo = state.itemCombo

    if (!itemCombo.produtoId) return false
    if (!itemCombo.quantidade) return false

    return true
  }

  const addItemCombo = () => {
    if (!validateItemCombo()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    const produto = state.produto
    const itemCombo = state.itemCombo

    const itens = produto.itensCombo

    if (itemCombo.index) {
      const index = produto.itensCombo.findIndex(param => param.index === itemCombo.index)

      itens[index] = {
        _id: itemCombo._id,
        produtoId: itemCombo.produtoId,
        produtoItem: itemCombo.produtoItem,
        quantidade: itemCombo.quantidade,
        ativo: itemCombo.ativo,
        duracao: itemCombo.duracao,
        ambientes: itemCombo.ambientes,
        principal: itemCombo.principal,
        index: itemCombo.index
      }
    } else {
      const index = itens.length + 1

      itens.push({
        produtoId: itemCombo.produtoId,
        produtoItem: itemCombo.produtoItem,
        quantidade: itemCombo.quantidade,
        ativo: itemCombo.ativo,
        duracao: itemCombo.duracao,
        ambientes: itemCombo.ambientes,
        principal: itemCombo.principal,
        index
      })

      // produto.duracao = produto.duracao + (itemCombo.duracao * itemCombo.quantidade)
    }

    produto.itensCombo = itens

    produto.duracao = somarValores(produto.itensCombo.map(value => {return value.duracao * value.quantidade }))

    setState(prevState => ({...prevState,
      produto,
      itemCombo: {
        produtoId: "",
        produtoItem: "",
        quantidade: "",
        duracao: 0,
        ambientes: "",
        ativo: true,
        principal: false
      },
      update: false
    }))
  }

  const editarItem = (index) => {
    const aux = state.produto.itensCombo.filter(param => param.index === index)

    setState(prevState => ({...prevState,
      update: true,
      itemCombo: aux[0]
    }))
  }

  const removerItem = (index) => {
    const produto = state.produto

    let itens = produto.itensCombo

    itens.splice(index - 1, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        _id:itens[i]._id,
        produtoId: itens[i].produtoId,
        produtoItem: itens[i].produtoItem,
        quantidade: itens[i].quantidade,
        duracao: itens[i].duracao,
        ambientes: itens[i].ambientes,
        ativo: itens[i].ativo,
        principal: itens[i].principal,
        index: i + 1
      })
    }

    itens = aux

    produto.itensCombo = itens

    produto.duracao = somarValores(produto.itensCombo.map(value => {return value.duracao * value.quantidade }))

    setState(prevState => ({...prevState, produto }))
  }

  const removeItemCombo = (index) => {
    const produto = state.produto

    let itensCombo = produto.itensCombo

    itensCombo.splice(index - 1, 1)

    const aux = []

    for (let i = 0; i < itensCombo.length; i++) {
      aux.push({
        produtoId: itensCombo[i].produtoId,
        quantidade: itensCombo[i].quantidade,
        duracao: itensCombo[i].duracao,
        ambientes: itensCombo[i].ambientes,
        ativo: itensCombo[i].ativo,
        principal: itensCombo[i].principal,
        index: i + 1
      })

    }

    itensCombo = aux

    produto.itensCombo = itensCombo

    produto.duracao = somarValores(produto.itensCombo.map(value => {return value.duracao * value.quantidade }))

    setState(prevState => ({...prevState, produto }))

  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      navigate("/produtos");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldCentroLucro = (event) => {
    
    const {centro_lucro, centros_lucro} = state

    let name = event.target.name
    let value = event.target.value

    centro_lucro[name] = value

    if(name === 'centrocusto_id'){
      if(value !== ''){
        const centro = centros_lucro.list.filter(param => param.id === parseInt(value))[0]
    
        centro_lucro.centrocusto = centro.centro_custo.descricao
      }
    }
    
    setState(prevState => ({...prevState,centro_lucro}))
  }

  const updateFieldCentroCusto = (event) => {
    
    const {centro_custo, centros_custo} = state

    let name = event.target.name
    let value = event.target.value

    centro_custo[name] = value

    if(name === 'centrocusto_id'){
      if(value !== ''){
        const centro = centros_custo.list.filter(param => param.id === parseInt(value))[0]
    
        centro_custo.centrocusto = centro.centro_custo.descricao
      }
    }
    
    setState(prevState => ({...prevState,centro_custo}))
  }

  const validateCentroLucro = () => {
    const centro_lucro = state.centro_lucro

    if (!centro_lucro.centrocusto_id) return false

    return true
  }

  const validateCentroCusto = () => {
    const centro_custo = state.centro_custo

    if (!centro_custo.centrocusto_id) return false

    return true
  }

  const addCentroLucro = () => {
    if (!validateCentroLucro()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    const produto = state.produto
    const centro_lucro = state.centro_lucro

    if(centro_lucro.valor_percentual > 100){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "O valor de rateio não pode ser superior a 100%."
        }
      }))
      return
    }

    const index = produto.centro_lucros.length

    produto.centro_lucros.push({
      centrocusto_id: parseInt(centro_lucro.centrocusto_id),
      centrocusto: centro_lucro.centrocusto,
      valor_percentual: (0).toFixed(2),
      ativo: true,
      index
    })

    for (let i = 0; i < produto.centro_lucros.length; i++) {
      let valor_percentual = (100/produto.centro_lucros.length).toFixed(2)
      
      produto.centro_lucros[i].valor_percentual = valor_percentual
    }

    let restante = (100 - somarValores(produto.centro_lucros.map(value => {return parseFloat(value.valor_percentual)}))).toFixed(2)
    restante = parseFloat(restante)

    if(restante > 0){
      const valor_percentual = parseFloat(produto.centro_lucros[produto.centro_lucros.length - 1].valor_percentual) + restante

      produto.centro_lucros[produto.centro_lucros.length - 1].valor_percentual = valor_percentual.toFixed(2)
    }

    setState(prevState => ({...prevState,
      produto,
      centro_lucro:{
        id: '',
        centrocusto_id: '',
        centrocusto: '',
        valor_percentual: 0,
        ativo: true,
        index: ''
      },
      updateCentro: false
    }))
  }

  const addCentroCusto = () => {
    if (!validateCentroCusto()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    const produto = state.produto
    const centro_custo = state.centro_custo

    if(centro_custo.valor_percentual > 100){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "O valor de rateio não pode ser superior a 100%."
        }
      }))
      return
    }

    const index = produto.centro_custos.length

    produto.centro_custos.push({
      centrocusto_id: parseInt(centro_custo.centrocusto_id),
      centrocusto: centro_custo.centrocusto,
      valor_percentual: (0).toFixed(2),
      ativo: true,
      index
    })

    for (let i = 0; i < produto.centro_custos.length; i++) {
      let valor_percentual = (100/produto.centro_custos.length).toFixed(2)
      
      produto.centro_custos[i].valor_percentual = valor_percentual
    }

    let restante = (100 - somarValores(produto.centro_custos.map(value => {return parseFloat(value.valor_percentual)}))).toFixed(2)
    restante = parseFloat(restante)

    if(restante > 0){
      const valor_percentual = parseFloat(produto.centro_custos[produto.centro_custos.length - 1].valor_percentual) + restante

      produto.centro_custos[produto.centro_custos.length - 1].valor_percentual = valor_percentual.toFixed(2)
    }

    setState(prevState => ({...prevState,
      produto,
      centro_custo:{
        id: '',
        centrocusto_id: '',
        centrocusto: '',
        valor_percentual: 0,
        ativo: true,
        index: ''
      },
      updateCentro: false
    }))
  }

  const updateFieldCentroAtivo = () =>{
    let {centro_lucro} = state
    centro_lucro.ativo = !centro_lucro.ativo
    setState(centro_lucro)
  }

  const editarItemCentro = (index) => {
    const aux = state.produto.centro_lucros.filter(param => param.index === index)

    setState(prevState => ({...prevState,
      updateCentro: true,
      centro_lucro: {
        id: aux[0].id,
        centrocusto_id: parseInt(aux[0].centrocusto_id),
        centrocusto: aux[0].centrocusto,
        valor_percentual: aux[0].valor_percentual,
        ativo: aux[0].ativo,
        index: aux[0].index
      }
    }))
  }

  const removerItemCentroLucro = (index) => {
    const produto = state.produto

    let itens = produto.centro_lucros

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        id: itens[i].id,
        departamento_id: itens[i].departamento_id,
        departamento:itens[i].departamento,
        centrocusto_id: itens[i].centrocusto_id,
        centrocusto: itens[i].centrocusto,
        valor_percentual: itens[i].valor_percentual,
        ativo: itens[i].ativo,
        index: i
      })
    }

    itens = aux

    produto.centro_lucros = itens

    setState(prevState => ({...prevState, produto }))
  }

  const removerItemCentroCusto = (index) => {
    const produto = state.produto

    let itens = produto.centro_custos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        id: itens[i].id,
        departamento_id: itens[i].departamento_id,
        departamento:itens[i].departamento,
        centrocusto_id: itens[i].centrocusto_id,
        centrocusto: itens[i].centrocusto,
        valor_percentual: itens[i].valor_percentual,
        ativo: itens[i].ativo,
        index: i
      })
    }

    itens = aux

    produto.centro_custos = itens

    setState(prevState => ({...prevState, produto }))
  }

  const handleCloseModalConfirmação = () => {
    setState(prevState => ({...prevState,
      openModalConfirmação: false
    }))
  }

  const updateFieldCentro = (event, index) => {
    let {produto} = state

    let valor = parseFloat(event.target.value).toFixed(2)
  
    if(valor > 100){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'O valor total do rateio não pode ser superior a 100%.'
        }
      }))
    }else{
      produto.centro_lucros[index][event.target.name] = event.target.value
    }
    

    setState(prevState => ({...prevState,produto}))
  }

  const validateForm = () => {
    const { produto } = state

    if (!produto.descricao) return false
    if (!produto.idSubGrupoProduto) return false
    if (!produto.embalagem) return false
    if (!produto.unidade_id) return false
    if (!produto.tipo) return false
    //if (!produto.planocontas_id) return false
    // if (!state.uploadedFiles.length === 0) return false

    if (produto.unidadeMedida === "COMBO") {
      produto.itensCombo.forEach(item => {

        if (!item.produtoId) return false
        if (!item.quantidade) return false
        if (!item.quantidade === 0) return false

        if (produto.itensCombo.length === 1) {
          if (item.quantidade === 1 || item.quantidade === 0) {
            return false
          }
        }

      });
    }

    return true
  }

  const salvar = async () => {
    const { produto, planocontas_anterior_id } = state

    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    if(parseInt(produto.planocontas_id) !== parseInt(planocontas_anterior_id)  && produto.possui_financeiro){
      setState(prevState => ({...prevState,
        openModalConfirmação: true,
        modalConfirmação:{
          mensagem: 'Você alterou o plano de contas do produto, as contas vinculadas a esse produto terão seus planos de contas alterado! Deseja continuar?'
        }
      }))
    }else{
      await confirmarSalvar(true)
    }

  }

  const confirmarSalvar = async (value) => {
    if(value){

      const { produto, ambientesSelecionado } = state


      // if(produto.centro_lucros.length === 0){
      //   setState(prevState => ({...prevState,
      //     alerta: {
      //       open: true,
      //       severity: 'warning',
      //       message: 'Informar os Centros de Lucro.'
      //     }
      //   }))
      //   return
      // }

      // if(produto.centro_custos.length === 0){
      //   setState(prevState => ({...prevState,
      //     alerta: {
      //       open: true,
      //       severity: 'warning',
      //       message: 'Informar os Centro de Custo.'
      //     }
      //   }))
      //   return
      // }
  
      if(produto.centro_lucros.length > 0){
        const total = somarValores(produto.centro_lucros.filter(param => param.ativo).map(centro => { return parseFloat(centro.valor_percentual)}))
       
        if(total !== 100){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'O valor total do rateio do centro de lucro precisa ser igual a 100%.'
            }
          }))
          return
        }
      }

      if(produto.centro_custos.length > 0){
        const total = somarValores(produto.centro_custos.filter(param => param.ativo).map(centro => { return parseFloat(centro.valor_percentual)}))
       
        if(total !== 100){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'O valor total do rateio do centro de custo precisa ser igual a 100%.'
            }
          }))
          return
        }
      }

      setState(prevState => ({...prevState, 
        loading: true,
        mensagemLoading: 'Cadastrando/Alterando Produto'
      }))
  
      const uploadedFile = state.uploadedFiles[0]
      let auxItensCombos = []
  
      produto.itensCombo.forEach(item => {
        auxItensCombos.push({
          _id: item._id,
          produtoId: item.produtoId,
          quantidade: item.quantidade,
          principal: item.principal,
          ativo: item.ativo
        })
      });
  
      let data = new FormData();
      if (state.trocaIcone) {
        data.append("file", uploadedFile.file, uploadedFile.name);
      }

      if (produto._id !== "") {
        if(produto.idMidia){
          data.append("idMidia", produto.idMidia);
        }
      }

      let produto_centro_lucro = []

      if(produto.centro_lucros.length > 0){
        produto_centro_lucro = produto.centro_lucros.map(value => {
          return {
            id: value.id,
            centrocusto_id: parseInt(value.centrocusto_id),
            planocontas_id: parseInt(produto.planocontas_receita_id),
            valor_percentual: parseFloat(value.valor_percentual),
            ativo: value.ativo
          }
        })
      }

      let produto_centro_custo = []

      if(produto.centro_custos.length > 0){
        produto_centro_custo = produto.centro_custos.map(value => {
          return {
            id: value.id,
            centrocusto_id: parseInt(value.centrocusto_id),
            planocontas_id: parseInt(produto.planocontas_id),
            valor_percentual: parseFloat(value.valor_percentual),
            ativo: value.ativo
          }
        })
      }
  
    
      data.append("descricao", produto.descricao);
      data.append("codigo", produto.codigo);
      data.append("unidadeMedida", produto.unidadeMedida);
      data.append("subGrupoProduto", produto.idSubGrupoProduto);
      data.append("visibilidade", produto.visibilidade);
      data.append("observacao", produto.observacao === '' ? 'N/A' : produto.observacao);
      data.append("embalagem", produto.embalagem);
      data.append("destaque", produto.destaque);
      data.append("unidade_id", produto.unidade_id);
      data.append("tipo", produto.tipo);
      data.append("ativo", produto.ativo);
      if(produto.planocontas_id){
        data.append("planocontas_id", produto.planocontas_id);
      }
      if(produto.planocontas_receita_id){
        data.append("planocontas_receita_id", produto.planocontas_receita_id);
      }
      if(produto_centro_lucro.length > 0){
        data.append("produto_centro_lucro", JSON.stringify(produto_centro_lucro));
      }
      if(produto_centro_custo.length > 0){
        data.append("produto_centro_custo", JSON.stringify(produto_centro_custo));
      }
      if (produto.unidadeMedida === "COMBO") {
        data.append("itensCombo", JSON.stringify(auxItensCombos));
      }
      if(produto.duracao !== ''){
        data.append("duracao", parseInt(produto.duracao));
      }
      if(ambientesSelecionado.length > 0){
        const array = ambientesSelecionado.map(value => {
          return {
            id: value.id
          }
        })

        data.append("ambientes", JSON.stringify(array));
      }

      const USER_TOKEN = localStorage.getItem('token')

      const config = {
        headers: {
          'Authorization': 'Bearer ' + USER_TOKEN,
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }

      try {
  
        if (produto._id !== "") {
          await axios.put(`${window._env_.REACT_APP_API_URL}/produtos/${produto._id}`, data, config)
        } else {
          await axios.post(`${window._env_.REACT_APP_API_URL}/produtos`, data, config)
        }
  
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Cadastro realizado com sucesso.'
          },
          loading: false
        }))
        
        backPage()
  
      }catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loading: false
        }))
      }
    }else{
      setState(prevState => ({...prevState,
        openModalConfirmação: false
      }))
    }
  }

  const updateFieldAmbiente = (event, value) =>{
    setState(prevState => ({...prevState,
      ambientesSelecionado: value
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/produto/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        produto: {
          _id: "",
          descricao: "",
          ativo: true,
          grupoProduto: "",
          idGrupoProduto: "",
          subGrupoProduto: "",
          idSubGrupoProduto: "",
          unidadeMedida: "",
          visibilidade: "",
          observacao: "",
          embalagem: "",
          destaque: false,
          itensCombo: [],
          unidade_id : "",
          planocontas_id: "",
          planocontas_receita_id: "",
          tipo: '',
          centro_lucros: [],
          centro_custos: [],
          possui_financeiro: false,
          estoque: {
            estoqueMinimo: "",
            estoqueAtual: "",
            valorVenda: "",
            precoCusto: ""
          }
        },
        centro_lucro:{
          id: '',
          centrocusto_id: '',
          centrocusto: '',
          valor_percentual: 0,
          ativo: true,
          index: ''
        },
        centro_custo:{
          id: '',
          centrocusto_id: '',
          centrocusto: '',
          valor_percentual: 0,
          ativo: true,
          index: ''
        },
        itemCombo: {
          produtoId: "",
          produtoItem: "",
          quantidade: "",
          duracao: 0,
          ambientes: "",
          principal: false,
          ativo: true
        }
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'produto')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (produtoId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message:'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message:'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
          const {produto} = state
          produto.unidade_id = unidades[0].id

          setState(prevState => ({...prevState,
            tipo: unidades[0].tipo
          }))
    
          await buscarGrupoProduto(unidades[0].id)
          await buscaPlanos(unidades[0].id)
          await buscarAmbientes(unidades[0].id)

          setState(prevState => ({...prevState,produto}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (produtoId) {
        try {

          const { data: produto } = await axios.get(`${window._env_.REACT_APP_API_URL}/produtos/${produtoId}`, getToken())

          const itensCombo = []

          await buscarGrupoProduto(parseInt(produto.unidade_id))
          let planoContas = await buscaPlanos(parseInt(produto.unidade_id))
          await buscarAmbientes(parseInt(produto.unidade_id))
          await buscarProdutos(parseInt(produto.unidade_id), parseInt(produto.subgrupoproduto.id))

          const subGrupoProdutos = state.subGrupoProdutos.list.filter(u => u.idGrupoProduto === parseInt(produto.subgrupoproduto.grupoproduto.id))

          setState(prevState => ({...prevState,
            subGrupoProdutosFiltrado: {
              list: subGrupoProdutos
            }
          }))
    
          produto.idSubGrupoProduto = ''

          if (produto.itemCombo) {
            for (let i = 0; i < produto.itemCombo.length; i++) {
              let ambientes = ''

              if(produto.itemCombo[i].produtoitem.servico){
                for (let j = 0; j < produto.itemCombo[i].produtoitem.servico.ambientes_servicos.length; j++) {
                  let ambiente = produto.itemCombo[i].produtoitem.servico.ambientes_servicos[j].ambiente
    
                  // if((i + 1) === produto.itemCombo[i].produtoitem.servico.ambientes_servicos.length){
                  //   ambientes = ambientes + `e ${ambiente.nome}.`
                  // }else{  
                  // }
                  ambientes = ambientes + `${ambiente.nome}, `
                }
              }

              itensCombo.push({
                _id: produto.itemCombo[i].id,
                produtoId: produto.itemCombo[i].idprodutoitem,
                produtoItem: produto.itemCombo[i].produtoitem.descricao,
                duracao: produto.itemCombo[i].produtoitem.servico ? (produto.itemCombo[i].produtoitem.servico.duracao || 0) : 0,
                quantidade: produto.itemCombo[i].quantidade,
                ativo: produto.itemCombo[i].ativo,
                principal: produto.itemCombo[i].principal ? true : false,
                ambientes,
                index: i + 1
              })
            }
          }

          let planocontas_id = ''
          let planocontas_receita_id = ''
          let centro_lucros = []
          let centro_custos = []
          
          if(produto.centro_lucro_produto.length > 0){
            const plano_selecionada = planoContas.filter(param => param.id === produto.centro_lucro_produto[0].centro_custo_plano.planocontas_id)[0]

            if(plano_selecionada){
              planocontas_receita_id = plano_selecionada.id
              centro_lucros = produto.centro_lucro_produto.map((value, key) => {
                return {
                  id: value.id,
                  centrocusto_id: value.centro_custo_plano.id,
                  centrocusto: value.centro_custo_plano.centro_custo.descricao,
                  valor_percentual: value.valor_percentual,
                  ativo: true,
                  index: key
                }
              })
              
              setState(prevState => ({...prevState,
                centros_lucro:{
                  list: plano_selecionada.centro_custos
                }
              }))
            }else{
              planocontas_receita_id = ''
              centro_lucros = []
              
              setState(prevState => ({...prevState,
                centros_lucro:{
                  list: []
                }
              }))
            }
          }

          if(produto.centro_custo_produto.length > 0){
            const plano_selecionada = planoContas.filter(param => param.id === produto.centro_custo_produto[0].centro_custo_plano.planocontas_id)[0]

            if(plano_selecionada){
              planocontas_id = plano_selecionada.id

              centro_custos = produto.centro_custo_produto.map((value, key) => {
                return {
                  id: value.id,
                  centrocusto_id: value.centro_custo_plano.id,
                  centrocusto: value.centro_custo_plano.centro_custo.descricao,
                  valor_percentual: value.valor_percentual,
                  ativo: true,
                  index: key
                }
              })
              
              setState(prevState => ({...prevState,
                centro_custos:{
                  list: plano_selecionada.centro_lucros
                }
              }))
            }else{
              planocontas_id = ''
              centro_custos = []
              
              setState(prevState => ({...prevState,
                centro_custos:{
                  list: []
                }
              }))
            }
          }

          if(produto.servico){
            if(produto.servico.ambientes_servicos.length > 0){
              setState(prevState => ({...prevState,
                ambientesSelecionado:produto.servico.ambientes_servicos.map(value => {
                  return {
                    id: value.ambiente_id,
                    nome: value.ambiente.nome
                  }
                })
              }))
            }       
          }

          setState(prevState => ({...prevState,
            produto: {
              _id: produto.id,
              descricao: produto.descricao,
              codigo: produto.codigo,
              ativo: produto.ativo,
              grupoProduto: produto.subgrupoproduto.grupoproduto.descricao,
              idGrupoProduto: produto.subgrupoproduto.grupoproduto.id,
              subGrupoProduto: produto.subgrupoproduto.descricao,
              idSubGrupoProduto: produto.subgrupoproduto.id,
              unidadeMedida: produto.unidademedida,
              visibilidade: produto.visibilidade,
              observacao: produto.observacao,
              embalagem: produto.embalagem,
              destaque: produto.destaque,
              idMidia: produto.idmidia,
              unidade_id: produto.unidade_id,
              planocontas_id,
              planocontas_receita_id,
              tipo: produto.tipo,
              possui_financeiro: produto.possui_financeiro,
              duracao: produto.servico ? produto.servico.duracao : 0,
              centro_lucros,
              centro_custos,
              itensCombo: itensCombo.sort((a, b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0))
            },
            uploadedFiles: [{
              id: produto.midia ? produto.midia.id : '',
              name: produto.midia ? produto.midia.nome : '',
              preview: produto.midia ? `${window._env_.REACT_APP_API_URL}/static/${produto.midia.key}` : '',
              uploaded: produto.midia ? true : false,
              url: produto.midia ? `${window._env_.REACT_APP_API_URL}/static/${produto.midia.key}` : ''
            }],
            planocontas_anterior_id: planocontas_id
          }))
        }catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
          return false
        }
      }

      setState(prevState => ({...prevState, loading: false}))
    };
    
    fetchData();
  }, []);

  const { produto, unidades, tipo, planoContas, tab, ambientes, ambientesSelecionado } = state

  // console.log(state.centros_lucro)

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12}>
                  <h1 className="titulo">Cadastro de Produtos</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mg_top_10">
                <Grid item md={12}>
                  <h4 className="titulo">Dados do Produto:</h4>
                </Grid>
              </Grid>
              <FormProduto 
                listaProdutos={state.produtos.list}
                grupoProdutos={state.grupoProdutos.list}
                subGrupoProdutos={state.subGrupoProdutosFiltrado.list}
                updateFieldAtivo={e => updateFieldAtivo(e)}
                updateFieldDestaque={e => updateFieldDestaque(e)}
                updateField={e => updateField(e)}
                updateFieldCombo={e => updateFieldCombo(e)}
                dados={produto}
                uploadedFiles={state.uploadedFiles}
                handleUpload={handleUpload}
                addItemCombo={(e) => addItemCombo()}
                removeItemCombo={(index) => removeItemCombo(index)}
                updateFieldItemAtivo={(index) => updateFieldItemAtivo(index)}
                itemCombo={state.itemCombo}
                editarItem={e => editarItem(e)}
                removerItem={e => removerItem(e)}
                update={state.update} 
                unidades={unidades.list}
                tipo={tipo}
                ambientes={ambientes.list}
                ambientesSelecionado={ambientesSelecionado}
                updateFieldAmbiente={(event, value) => updateFieldAmbiente(event, value)}
                updateFieldItemPrincipal={e => updateFieldItemPrincipal(e)}
              />
              <hr />
              <FormCentroCustoProduto 
                dados={produto}
                centro_lucro={state.centro_lucro}
                centro_custo={state.centro_custo}
                updateFieldCentroLucro={e => updateFieldCentroLucro(e)}
                updateFieldCentroCusto={e => updateFieldCentroCusto(e)}
                centros_lucro={state.centros_lucro.list}
                centros_custo={state.centros_custo.list}
                addCentroLucro={e => addCentroLucro()}
                addCentroCusto={() => addCentroCusto()}
                updateFieldCentroAtivo={e => updateFieldCentroAtivo(e)}
                updateFieldCentro={(e, index) => updateFieldCentro(e, index)}
                editarItemCentro={e => editarItemCentro(e)}
                removerItemCentroLucro={e => removerItemCentroLucro(e)}
                removerItemCentroCusto={e => removerItemCentroCusto(e)}
                updateCentro={state.updateCentro}
                planoContas={planoContas.list}
                updateField={e => updateField(e)}
              />
              <hr className="mg_top_10"/>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />} onClick={e => backPage(0)}>Voltar</Button>
                </Grid>
                <Grid item md={8} xs={false} sm={6}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button disabled={state.loading} fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
              <br />
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message}
          />
          <ModalConfirmação 
            open={state.openModalConfirmação}
            handleClose={e => handleCloseModalConfirmação()} 
            dados={state.modalConfirmação}
            confirmar={e => confirmarSalvar(e)}
          />
        </div >
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Produto"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroProduto