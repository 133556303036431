import React, { useState, useEffect } from 'react';
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from './../../../components/templates/Alert'
import UploadImage from './../../../services/uploads/UploadImage'
import {filesize} from "filesize";
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useNavigate } from 'react-router-dom';
import {
  ImagesProvider,
  ThumbnailOptionsProvider,
  Thumbnails
} from "react-thumbnails";

const initialState = {
  unidade: {},
  loading: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loadingSalvar: false,
  itens: {
    list: []
  },
  openModalSelecionarLogo: false,
  logo_unidade:{
    id: '',
    key: ''
  },
  uploadedFiles: [],
  deleteImagem: false
}

const Midias = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleUploadImage = (files) => {
    const unidade = props.unidade
    
    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      index: state.uploadedFiles.length + key,
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      caminho_uploads: unidade.caminho_uploads,
      url: null,
    }))

    concatUploadedFiles(uploadedFiles)
  }

  const concatUploadedFiles = async (uploadedFiles) => {
    await processUpload(uploadedFiles)
  }

  const processUpload = async (uploadedFile) => {
    const unidade = props.unidade
    const data = new FormData();
    const USER_TOKEN = localStorage.getItem('token')
    let {uploadedFiles} = state
    
    data.append("file", uploadedFile[0].file, uploadedFile[0].name);
    
    await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/imagem/${unidade.pessoa.id}/${unidade.id}`, data, {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      onUploadProgress: e => {
        // const progress = parseInt(Math.round((e.loaded * 100) / e.total));

        // let newUploadedFiles = {
        //   ...uploadedFile[0],
        //   progress
        // }
  
        // uploadedFiles = uploadedFiles.concat(newUploadedFiles)
  
        // setState((prevState) => ({
        //   ...prevState,
        //   uploadedFiles
        // }))
      }
    }).then(response => {

      let newUploadedFiles = {
        ...uploadedFile[0],
        uploaded: true,
        id: response.data.id,
        key: response.data.key,
        dimensoes: response.data.dimensoes
      }

      uploadedFiles = uploadedFiles.concat(newUploadedFiles)

      setState((prevState) => ({
        ...prevState,
        uploadedFiles,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Imagem Salva!'
        }
      }))


    }).catch((error) => {
      console.log(error)

      // let newUploadedFiles = {
      //   ...uploadedFile[0],
      //   error: true
      // }

      // uploadedFiles = uploadedFiles.concat(newUploadedFiles)

      setState(prevState => ({...prevState,
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: 'Erro Interno'
        }
      }))
    });
  }

  const voltar = () => {
    navigate("/unidade");
    return;
  }

  const removerImagem = async (img) => {
    try {
      await axios.delete(`${window._env_.REACT_APP_API_URL}/pessoa/imagem/${img.id}`, getToken())
    
      const {uploadedFiles} = state

      let index = uploadedFiles.findIndex(param => param.id === img.id)

      uploadedFiles.splice(index, 1)

      setState(prevState => ({...prevState,
        uploadedFiles
      }))

      window.location.reload()
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }
  
  const alterarLogo = async (img) => {
    try {
      const unidade = props.unidade

      await axios.put(`${window._env_.REACT_APP_API_URL}/unidades/alterarLogo/${unidade.id}`, {midia_id: img.id}, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Logo alterada'
        }
      }))

      window.location.reload()
    } catch (error) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const unidade = props.unidade

      setState(prevState => ({...prevState,
        unidade: unidade,
        loading: false,
        uploadedFiles: unidade.pessoa.midias_pessoa.map((value, key) => {
          return {
            id: value.midia ? value.midia.id : '',
            name: value.midia ? value.midia.nome : '',
            preview: value.midia ? `${window._env_.REACT_APP_API_URL}/static/${value.midia.key}` : '',
            uploaded: value.midia ? true : false,
            url: value.midia ? `${window._env_.REACT_APP_API_URL}/static/${value.midia.key}` : '',
            dimensoes: value.midia ? value.midia.dimensoes : '',
            caminho_uploads: unidade.caminho_uploads,
            logo_unidade: value.midia.logo_unidade ? true: false,
            index: key,
          }
        })
      }))
    };
    
    fetchData();
  }, []);

  const { uploadedFiles } = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Midias...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
            <Grid item md={6} xs={12} sm={12}>
              <h1 className="titulo">Midias: </h1>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <UploadImage onUpload={e => handleUploadImage(e)} />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            {uploadedFiles.map((img, key) => {
              return (
                <Grid key={key} item md={2} xs={12} sm={4} style={{textAlign: 'right', marginTop: 10}}>
                  <div style={{position: 'relative'}}>
                    {img.logo_unidade &&
                      <Tooltip title='Logo da Unidade' aria-label="add">
                        <IconButton color="primary" aria-label="editar" style={{position: 'absolute', right: '0px', zIndex: 999}}>
                          <CheckCircleIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    {!img.logo_unidade &&
                      <Tooltip title='Selecionar nova Logo' aria-label="add">
                        <IconButton color="primary" aria-label="editar" style={{position: 'absolute', right: '0px', zIndex: 999}} onClick={() => alterarLogo(img)}>
                          <RadioButtonUncheckedIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    <br />
                    <ImagesProvider
                      options={{ maxImagesCount: 1 }}
                      urls={[img.preview]}
                    >
                      <ThumbnailOptionsProvider
                        defaults={{
                          size: "medium",
                          shadow: false, 
                          border: false
                        }}
                      >
                        <Thumbnails />
                      </ThumbnailOptionsProvider>
                    </ImagesProvider>
                    {/* <img style={{width:'100%'}} src={img.preview} alt="Imagem" />
                    <span style={{fontSize:'10px'}}>{img.dimensoes} </span>*/}
                    <Tooltip title='Excluir Midia' aria-label="add">
                      <span className='excluir_img' onClick={e => removerImagem(img)}>EXCLUIR</span> 
                    </Tooltip>
                  </div>
                </Grid>
              )
            })}
          </Grid>
          <hr className="mg_top_10" />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={9} xs={false} sm={8}></Grid>
            <Grid item md={3} xs={false} sm={4}>
              <Button fullWidth onClick={() => voltar()} color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
            </Grid>
            {/* <Grid item md={3} xs={false} sm={4}>
              <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
            </Grid> */}
          </Grid>
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
    </React.Fragment>
  )

}

export default Midias