import React, { useState, useEffect } from 'react';
import '../ContaPagarReceber.scss'
import '../../../main/ultil.scss'
import { Grid, Button } from '@mui/material'
import FormContaPagarReceber from '../../../components/forms/FormContaPagarReceber'
import FormContaPRCentroCusto from '../../../components/forms/FormContaPRCentroCusto'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../../components/templates/Alert'
import ModalSimple from '../../../components/modals/Confirmacao'
import ModalSalvarBaixa from '../../../components/modals/SalvarBaixa'
import ModalSelecionaPessoa from './../../../components/modals/SelecionaPessoa'
import ModalConfirmacao from './../../../components/modals/Confirmacao'
import axios from 'axios'
import moment from 'moment';
import moeda from '../../../services/moeda'
import real from '../../../services/real';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { format } from 'cnpj'
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');

const initialState = {
  conta: {
    descricao: "",
    tipo: "",
    valortotal: "",
    numparcela: 1,
    item_id: "",
    qtd_parcelas: 1,
    recorrenciaACada: 1,
    tipoRecorrencia: 'M',
    data_documento: '',
    unidade_id: '',
    parcelas: [
      {
        baixado: "",
        datavencimento: "",
        datavencimento_orig: "",
        valorparcela: "",
        formapagto_id: "",
        contfin_id: "",
        documento: "",
        habilita: "",
        num: "",
        parcela_editada: false,
        index: 1
      }
    ],
    parcelado: false,
    recorrencia: false,
    qtdRecorrencia: 1
  },
  planoContas: {
    id: "",
    codigo: "",
    descricao: "",
    ativo: "",
    parametros: {
      parcelamento: 'Mensal'
    }
  },
  planosConta: {
    list: []
  },
  planosContaFiltrado: {
    list: []
  },
  itemSelecionado:{
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: ''
  },
  qtdParcelasEditado: 0,
  contasFinanceira: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  modalErro: false,
  modalSalvarBaixa: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  update: false,
  modalCaixaOpen: false,
  modalCaixa: {
    mensagem: '',
    situacao: ''
  },
  permissoesContasPR: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesCaixa: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  perfilLogado: '',
  disabledButtons: false,
  salvarBaixa:{
    id: '',
    formapagto_id: '',
    valor_pagamento: real(0),
    descricao: '',
    hab_caixa: false,
    data_pagamento: '',
    contfin_id: ''
  },
  disabledButtonsSalvar: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  centro_custos: [],
  centro_custo:{
    id: '',
    departamento_id: '',
    departamento: '',
    centrocusto_id: '',
    centrocusto: '',
    valor_percentual: 0,
    valor_rateio: 0,
    ativo: true,
    index: ''
  },
  departamentos: {
    list: []
  },
  centros_custo_list: {
    list: []
  },
  tab: 0,
  clientes: {
    list: []
  },
  fornecedores: {
    list: []
  },
  cliente: {
    pessoa_id: '',
    tipo: '',
    flags: [],
    nome: '',
    cpf_cnpj: '',
    endereco: '',
    telefone: '',
    email: '',
    ativo: ''
  },
  modalSelecionaPessoa: false,
  filtro: {
    nome: "",
    cpf: "",
    cnpj: "",
    flag: "",
    nomefantasia: "",
    ativo: 'Sim',
    filtrar_por: "Nome - nome",
    value: ''
  },
  filtrocliente: {
    label: 'Nome',
    name:'nome'
  },
  loadingPessoas: false,
  pessoasFiltrado: {
    list: []
  },
  pessoas: {
    list: []
  },
  unidades: {
    list: []
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao:{
    message: ''
  },
  baixar: false,
  disabled: false,
  produtos: {
    list: []
  },
  itens_receita_despesa: {
    list: []
  },
  travarCampos: false
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CadastroContasPR = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = async (event) => {
    let {conta, centro_custos} = state

    let value = event.target.value
    let name = event.target.name

    if(name === 'tipo'){

      await consultarItens(value)

      conta = {
        descricao: "",
        tipo: "",
        valortotal: "",
        numparcela: 1,
        item_id: "",
        qtd_parcelas: 1,
        recorrenciaACada: 1,
        tipoRecorrencia: 'M',
        data_documento: '',
        unidade_id: conta.unidade_id,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            datavencimento_orig: "",
            valorparcela: "",
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: "",
            parcela_editada: false,
            index: 1
          }
        ],
        parcelado: false,
        recorrencia: false,
        qtdRecorrencia: 1
      }

      setState(prevState => ({...prevState,
        itemSelecionado:{
          id: '',
          codigo: '',
          descricao: '',
          ativo: '',
          slug: '',
          centro_custos: '',
          totalizador: ''
        },
        centro_custo:{
          id: '',
          departamento_id: '',
          departamento: '',
          centrocusto_id: '',
          centrocusto: '',
          valor_percentual: 0,
          valor_rateio: 0,
          ativo: true,
          index: ''
        },
        cliente: {
          pessoa_id: '',
          tipo: '',
          flags: [],
          nome: '',
          cpf_cnpj: '',
          endereco: '',
          telefone: '',
          email: '',
          ativo: ''
        }
      }))
    }

    conta[name] = value

    setState(prevState => ({...prevState, conta }))

    if (name === 'valortotal') {

      let valorTotal = parseFloat(value.replaceAll(".", "").replaceAll(",", "."))

      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
      
      for (let i = 0; i < centro_custos.length; i++) {
        centro_custos[i].valor_rateio = real((valorTotal * centro_custos[i].valor_percentual)/100)
      }

      let restante_valor_rateio = (valorTotal - somarValores(centro_custos.map(value => {return parseFloat(value.valor_rateio.replaceAll(".", "").replaceAll(",", "."))})))
      restante_valor_rateio = parseFloat(restante_valor_rateio).toFixed(2)

      if(centro_custos.length > 0){
        if(restante_valor_rateio !== 0){
          const valor_rateio = parseFloat(centro_custos[centro_custos.length - 1].valor_rateio.replaceAll(".", "").replaceAll(",", ".")) + parseFloat(restante_valor_rateio)
  
          centro_custos[centro_custos.length - 1].valor_rateio = real((valor_rateio))
        }
      }

      setState(prevState => ({...prevState,centro_custos}))
    }

    if(name === 'unidade_id'){
      setState(prevState => ({...prevState,loadingPessoas: true}))

      try {
        const dados = {
          unidadesnegocio: [parseInt(value)]
        }
  
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/list`, dados, getToken())
  
        const departamentos = []
        
        data.forEach(departamento => {
          if(departamento.unidade){
            departamentos.push({
              id: departamento.id,
              descricao: departamento.descricao,
              unidade: departamento.unidade.descricao ? departamento.unidade.descricao : (departamento.unidade.pessoa.tipo === 'FISICA' ? departamento.unidade.pessoa.fisica.nome : departamento.unidade.pessoa.juridica.nomefantasia),
              centro_custos: departamento.centro_custos,
              ativo: departamento.ativo
            })
          }
        })
  
        setState(prevState => ({...prevState,
          departamentos: {
            list: departamentos
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      try {
        const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${parseInt(conta.unidade_id)}`, getToken())
  
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
  
    }

    setState(prevState => ({...prevState, conta }))
  }

  const consultarItens = async (tipo) => {
    try {
  
      let {conta} = state

      const dados = {
        unidadesnegocio: [parseInt(conta.unidade_id)],
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados,getToken())
             
      let itens_receita_despesa = data.filter(param => param.ativo && param.plano_contas.tipo === `${tipo === 'PAGAR' ? 'DESPESA' : 'RECEITA'}`).map(item => {
        return {
          id: item.id,
          descricao: item.descricao,
          planocontas_id: item.planocontas_id,
          plano_contas: item.plano_contas
        }
      })

      setState(prevState => ({...prevState,
        itens_receita_despesa: {
          list: itens_receita_despesa
        }
      }))

      return itens_receita_despesa

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldCentro = (event, index) => {
    let {centro_custos} = state

    if(event.target.name === 'valor_percentual'){
      let valor = parseFloat(event.target.value).toFixed(2)
    
      if(valor > 100){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'O valor total do rateio não pode ser superior a 100%.'
          }
        }))
      }else{
        centro_custos[index][event.target.name] = event.target.value
      }

    }else{
      centro_custos[index][event.target.name] = event.target.value
    }

    setState(prevState => ({...prevState,centro_custos}))
  }

  const ajustaValorCentro = (event, index) => {
    let valor = parseFloat(event.target.value.replaceAll(".", "").replaceAll(",", "."))

    let {conta, centro_custos} = state
    
    let valor_total = parseFloat(conta.valortotal.replaceAll(".", "").replaceAll(",", "."))

    if(event.target.name === 'valor_rateio'){
     
      centro_custos[index].valor_rateio = real(valor)
      centro_custos[index].valor_percentual = ((parseFloat(centro_custos[index].valor_rateio.replaceAll(".", "").replaceAll(",", "."))/valor_total)*100).toFixed(2)

      // for (let i = 0; i < centro_custos.length; i++) {
      //   if(centro_custos[i].index !== index){
      //     centro_custos[i].valor_rateio = real(valor_restante/(centro_custos.length - 1))
      //   }
      // }

      // for (let i = 0; i < centro_custos.length; i++) {
      //   centro_custos[i].valor_percentual = ((parseFloat(centro_custos[i].valor_rateio.replaceAll(".", "").replaceAll(",", "."))/valor_total)*100).toFixed(2)
      // }
    }

    if(event.target.name === 'valor_percentual'){
      centro_custos[index].valor_percentual = parseFloat(event.target.value).toFixed(2)
      centro_custos[index].valor_rateio =  real((valor_total * centro_custos[index].valor_percentual)/100)

      // for (let i = 0; i < centro_custos.length; i++) {
      //   if(centro_custos[i].index !== index){
      //     centro_custos[i].valor_percentual = parseFloat((100-parseInt(event.target.value))/(centro_custos.length - 1)).toFixed(2)
      //   }
      // }

      // for (let i = 0; i < centro_custos.length; i++) {
      //   centro_custos[i].valor_rateio =  real((valor_total * centro_custos[i].valor_percentual)/100)
      // }
    }

    setState(prevState => ({...prevState,centro_custos}))
  }

  const validateCentroCusto = () => {
    const centro_custo = state.centro_custo

    if (!centro_custo.centrocusto_id) return false

    return true
  }

  const addCentroCusto = () => {
    if (!validateCentroCusto()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    let {centro_custos, centro_custo, conta} = state

    const index = centro_custos.length

    centro_custos.push({
      departamento_id: centro_custo.departamento_id,
      departamento:centro_custo.departamento,
      centrocusto_id: centro_custo.centrocusto_id,
      centrocusto: centro_custo.centrocusto,
      valor_percentual: (0).toFixed(2),
      valor_rateio: real(0),
      ativo: true,
      index
    })

    let valor_total = parseFloat(conta.valortotal.replaceAll(".", "").replaceAll(",", "."))

    for (let i = 0; i < centro_custos.length; i++) {
      let valor_percentual = (100/centro_custos.length).toFixed(2)

      centro_custos[i].valor_percentual = valor_percentual
      centro_custos[i].valor_rateio = real((valor_total * valor_percentual)/100)
    }

    let restante = (100 - somarValores(centro_custos.map(value => {return parseFloat(value.valor_percentual)}))).toFixed(2)
    restante = parseFloat(restante)

    if(restante > 0){
      const valor_percentual = parseFloat(centro_custos[centro_custos.length - 1].valor_percentual) + restante

      centro_custos[centro_custos.length - 1].valor_percentual = valor_percentual.toFixed(2)
      centro_custos[centro_custos.length - 1].valor_rateio = real((valor_total * valor_percentual)/100)
    }

    let restante_valor_rateio = (valor_total - somarValores(centro_custos.map(value => {return parseFloat(value.valor_rateio.replaceAll(".", "").replaceAll(",", "."))})))
    restante_valor_rateio = parseFloat(restante_valor_rateio).toFixed(2)

    if(restante_valor_rateio !== 0){
      const valor_rateio = parseFloat(centro_custos[centro_custos.length - 1].valor_rateio.replaceAll(".", "").replaceAll(",", ".")) + parseFloat(restante_valor_rateio)

      centro_custos[centro_custos.length - 1].valor_rateio = real((valor_rateio))
    }

    setState(prevState => ({...prevState,
      centro_custos,
      centro_custo:{
        id: '',
        departamento_id: '',
        departamento: '',
        centrocusto_id: '',
        centrocusto: '',
        valor_percentual: 0,
        valor_rateio: 0,
        ativo: true,
        index: ''
      }
    }))
  }

  const removeCentro = (index) => {
    let {centro_custos} = state

    centro_custos.splice(index, 1)

    const aux = []

    for (let i = 0; i < centro_custos.length; i++) {
      aux.push({
        id: centro_custos[i].id,
        departamento_id: centro_custos[i].departamento_id,
        departamento:centro_custos[i].departamento,
        centrocusto_id: centro_custos[i].centrocusto_id,
        centrocusto: centro_custos[i].centrocusto,
        valor_percentual: centro_custos[i].valor_percentual,
        valor_rateio: centro_custos[i].valor_rateio,
        ativo: centro_custos[i].ativo,
        index: i
      })
    }

    centro_custos = aux

    // for (let i = 0; i < centro_custos.length; i++) {
    //   centro_custos[i].valor_rateio = real(valor_total/(centro_custos.length))
    // }

    // for (let i = 0; i < centro_custos.length; i++) {
    //   centro_custos[i].valor_percentual = ((parseFloat(centro_custos[i].valor_rateio.replaceAll(".", "").replaceAll(",", "."))/valor_total)*100).toFixed(2)
    // }

    setState(prevState => ({...prevState, centro_custos }))
  }

  const updateFieldParcela = async (event, index) => {
    const {conta, formasPagamento} = state

    let value = event.target.value
    let name = event.target.name

    if(name === 'datavencimento'){
      conta.parcelas[index - 1][name] = value
    }else{
      conta.parcelas[index - 1][name] = value
    }

    if(name === 'formapagto_id'){
     const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]

     conta.parcelas[index - 1].habilita = formaPagtoSelecionada.habilita

      if(!state.travarCampos){
        conta.parcelas[index - 1].contfin_id = "" 
      }
    }
    
    setState(prevState => ({...prevState, conta }))
  }

  const marcaParcelas = (event, index) => {
    
    const {conta, formasPagamento} = state
        
    const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(formaPagtoSelecionada){
        conta.parcelas[i].formapagto_id = event.target.value
        conta.parcelas[i].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[i].contfin_id = ""
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const marcaParcelasContaFin = (event, index) => {
    
    const {conta, contasFinanceira} = state
        
    const contaFinanceiraSelecionada = contasFinanceira.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(contaFinanceiraSelecionada){
        if(conta.parcelas[i].habilita === contaFinanceiraSelecionada.tipo_conta){
          conta.parcelas[i].contfin_id = event.target.value
        }
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const marcaContasParcelas = (event, index) => {
    const {conta, contasFinanceira} = state
        
    for (let i = index; i < conta.parcelas.length; i++) {
      const contaSelecionada = contasFinanceira.list.filter(param => param.id === parseInt(event.target.value))[0]

      if(contaSelecionada){
        if(conta.parcelas[i].habilita === contaSelecionada.tipo_conta){
          conta.parcelas[i].contfin_id = event.target.value
        }
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const verificaDataParcela = (event, index) => {
    const {conta} = state
    
    for (let i = index; i < conta.parcelas.length; i++) {
      let count = (i - index ) + 1

      let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

      conta.parcelas[i].datavencimento = novaData
      conta.parcelas[i].datavencimento_orig = novaData
    }

    setState(prevState => ({...prevState, conta }))
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valortotal.replaceAll(".", "").replaceAll(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replaceAll(".", "").replaceAll(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)

      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const mudarParcelas = (event) => {
    const conta = { ...state.conta }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.valortotal.replaceAll(".", "").replaceAll(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i))

      array.push({
        baixado: "",
        datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
        valorparcela: real(valorDividido),
        formapagto_id: "",
        documento: "",
        num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array

    setState(prevState => ({...prevState, conta }))
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = real((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const backPage = (timeout = 3000) => {
    const self = this
    setTimeout(() => {
      const ultima_tela_financeiro = JSON.parse(localStorage.getItem('ultima_tela_financeiro'))

      if(ultima_tela_financeiro){
        if (ultima_tela_financeiro.tela === 'contasPR') {
          let url = criaUrl()
  
          navigate(url);
        } else if (ultima_tela_financeiro.tela === 'cliente') {
          if (self.props.cliente) {
            window.location.reload()
          } else {
            navigate(`/cliente/view/${ultima_tela_financeiro.matricula}/financeiro`)
          }
        }else if (ultima_tela_financeiro.tela === 'preContasPR') {
          navigate(`/preContasPR`)
        }else if(ultima_tela_financeiro.tela === 'extrato_conciliacao'){
          navigate(`/movimentacao_financeira/importar_extrato`)
        }else {
          window.location.reload()
        }
      }else {
        window.location.reload()
      }

    }, timeout)
    setState(prevState => ({...prevState,
      conta: initialState.conta
    }))
  }

  const verificaCaixaInicial = (state) => {
    if (!state.ultimoCaixa || !state.logado) return ''
    const { ultimoCaixa, perfilLogado } = state

    if (
      perfilLogado === 'caixa' &&
      (!Object.keys(ultimoCaixa).length || (ultimoCaixa && ultimoCaixa.fechado))
    ) {
      return {
        libera: false,
        msg: 'É necessário abrir um caixa!'
      }
    }

    if (
      perfilLogado === 'caixa' &&
      ultimoCaixa &&
      !ultimoCaixa.fechado
    ) {

      const dataCaixa = moment(ultimoCaixa.dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        return {
          libera: false,
          msg: 'O caixa não é de hoje, é necessário fechar o anterior e abrir um novo!'
        }
      }
    }

    return {
      libera: true,
      msg: ''
    }
  }

  const verificaCaixa = () => {
    const { ultimoCaixa, perfilLogado } = state

    if (
      perfilLogado === 'caixa' &&
      (
        !Object.keys(ultimoCaixa).length ||
        (ultimoCaixa && ultimoCaixa.fechado)
      )
    ) {
      setState(prevState => ({...prevState,
        modalCaixa: {
          mensagem: 'É necessário abrir um caixa, deseja abrir um caixa?',
          situacao: 'ABRIR'
        },
        modalCaixaOpen: true,
      }))
      return false
    }

    if (
      perfilLogado === 'caixa' &&
      ultimoCaixa &&
      !ultimoCaixa.fechado
    ) {
      const dataCaixa = moment(ultimoCaixa.dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        // verificar Data abertura
        setState(prevState => ({...prevState,
          modalCaixa: {
            mensagem: 'Opps, O caixa não é de hoje, é necessario fechar o anterior e abrir um novo, deseja ser redirecionado para o fechamento?',
            situacao: 'FECHAR'
          },
          modalCaixaOpen: true,
        }))
        return false
      }
    }

    return true
  }

  const confirmarCaixa = (resposta) => {
    const { modalCaixa, ultimoCaixa } = state

    if (resposta) {

      if (modalCaixa.situacao === 'ABRIR') {
        navigate({ pathname: `/caixa/cadastro` })
      }

      if (modalCaixa.situacao === 'FECHAR') {
        navigate({ pathname: `/caixa/view/${ultimoCaixa.id}` })
      }

    } else {
      handleClose()
    }
  }

  const handleCloseSalvarBaixa = () => {
    setState(prevState => ({...prevState,
      modalSalvarBaixa: false
    }))
  }

  const handleClose = () => {
    setState(prevState => ({...prevState,
      modalCaixaOpen: false
    }))
  }
  
  const updateFieldBaixaParcela = (event) => {
    let { salvarBaixa, formasPagamento, contasFinanceira } = state

    if (event.target.name === 'formapagto_id') {
      const itens = formasPagamento.list
      const index = itens.findIndex(param => param.id === parseInt(event.target.value))

      salvarBaixa[event.target.name] = parseInt(event.target.value)
      salvarBaixa.descricao = itens[index].descricao
      salvarBaixa.hab_caixa = itens[index].hab_caixa
    } else if (event.target.name === 'contfin_id') {
      const itens = contasFinanceira.list
      const index = itens.findIndex(param => param.id === parseInt(event.target.value))

      salvarBaixa[event.target.name] = parseInt(event.target.value)
      salvarBaixa.contfin_descricao = itens[index].contfin_descricao
      salvarBaixa.contfin_tipo_conta = itens[index].contfin_tipo_conta
           
    } else {
      salvarBaixa[event.target.name] = event.target.value
    }

    setState(prevState => ({...prevState, salvarBaixa }))
  }

  const confirmarBaixaSalvar = (value) => {
    if(value){
      setState(prevState => ({...prevState,disabledButtonsSalvar: true}))
      const {salvarBaixa} = state
      
      if(salvarBaixa.contfin_id === ''){
        setState(prevState => ({...prevState,
          disabledButtonsSalvar: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a Contas Bancárias.'
          }
        }))
        return
      }
  
      if(salvarBaixa.formapagto_id === ''){
        setState(prevState => ({...prevState,
          disabledButtonsSalvar: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a forma de pagamento.'
          }
        }))
        return
      }
  
      if(salvarBaixa.data_pagamento === ''){
        setState(prevState => ({...prevState,
          disabledButtonsSalvar: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data de pagamento.'
          }
        }))
        return
      }
  
      setState(prevState => ({...prevState,disabledButtonsSalvar: true}))
  
      salvar(true)
    }else{
      handleCloseSalvarBaixa()
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldCentroCusto = (event) => {
    const {centro_custo, departamentos, centros_custo_list} = state
    centro_custo[event.target.name] = event.target.value

    if(event.target.name === 'departamento_id'){
      
      const departamento = departamentos.list.filter(param => param.id === parseInt(event.target.value))[0]
      setState(prevState => ({...prevState,
        centros_custo_list: {
          list: departamento.centro_custos
        }
      }))

      centro_custo.departamento = departamento.descricao
    }

    if(event.target.name === 'centrocusto_id'){
      const centro = centros_custo_list.list.filter(param => param.id === parseInt(event.target.value))[0]
   
      centro_custo.centrocusto = centro.descricao
    }
    
    setState(prevState => ({...prevState,centro_custo}))
  }

  const handleChange = (event, newValue) => {
    setState(prevState => ({...prevState,
      tab: newValue
    }))
  };

  const buscarPessoa = async () => {
    setState(prevState => ({...prevState,
      modalSelecionaPessoa: true
    }))
  }

  const updateFiltro = (event) => {
    const filtro = state.filtro

    if (event.target.name === 'filtrar_por') {    
      setState(prevState => ({...prevState,
        filtrocliente: {
          label: event.target.value.split(' - ')[0],
          name: event.target.value.split(' - ')[1]
        }
      }))

      filtro[event.target.name] = event.target.value

      setState(prevState => ({...prevState,
        filtro
      }))
    }else{
      filtro[event.target.name] = event.target.value
      
      setState(prevState => ({...prevState,
        filtro
      }))
    }
  }

  const filtrarCliente = async () => {
    setState(prevState => ({...prevState,
      loadingPessoas: true,
    }))

    try {
     
      let {filtro, filtrocliente, conta} = state

      filtro = {
        flag: filtro.flag,
        ativo: filtro.ativo,
        filtrar_por: filtro.filtrar_por,
        value: filtro.value,
        nome: "",
        cpf: "",
        cnpj: "",
        nomefantasia: "",
      }
      
      filtro[filtrocliente.name] = filtro.value ? filtro.value : ''
      
      filtro.unidadesnegocio = [parseInt(conta.unidade_id)]

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/list`, filtro, getToken())

      const pessoas = []

      data.forEach(pessoa => {

        pessoas.push({
          pessoa_id: pessoa.id,
          tipo: pessoa.tipo,
          nome: pessoa.tipo === 'FISICA' ? `${pessoa.fisica.nome} ${pessoa.fisica.sobrenome || ''}` : `${pessoa.juridica.nomefantasia} - ${pessoa.juridica.razaosocial}`,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? pessoa.fisica.cpf : pessoa.juridica.cnpj,
          flags: pessoa.flags.map(value => value.flag),
          endereco: pessoa.enderecos[0] ? `${pessoa.enderecos[0].logradouro} ${pessoa.enderecos[0].numero}, ${pessoa.enderecos[0].bairro}, ${pessoa.enderecos[0].cidade} - ${pessoa.enderecos[0].estado}` : '',
          telefone: pessoa.telefones[0] ? pessoa.telefones[0].numero : '',
          email: pessoa.emails[0] ? pessoa.emails[0].email : '',
          ativo: pessoa.ativo
        })
      })

      setState(prevState => ({...prevState,
        pessoasFiltrado: {
          list: pessoas
        },
        loadingPessoas: false
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        loadingPessoas: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const selecionarPessoa = (row) => {
    setState(prevState => ({...prevState,
      cliente: {
        pessoa_id: row.pessoa_id,
        tipo: row.tipo,
        flags: row.flags,
        nome: row.nome,
        cpf_cnpj: row.cpf_cnpj,
        endereco: row.endereco,
        telefone: row.telefone,
        email: row.email,
        ativo: row.ativo
      },
      modalSelecionaPessoa: false
    }))
  }

  const validateForm = () => {
    const { conta, itemSelecionado } = state
    
    if (!conta.descricao) return false
    if (!conta.tipo) return false
    if (!conta.valortotal) return false
    if (!conta.numparcela) return false
    // if (!conta.item_id) return false
    if (!conta.unidade_id) return false
    if(!itemSelecionado.id) return false

    for (let i = 0; i < conta.parcelas.length; i++) {
      if (!conta.parcelas[i].datavencimento) return false
      if (!conta.parcelas[i].valorparcela) return false
      if (!conta.parcelas[i].formapagto_id) return false
    }

    return true
  }

  const validateFormCaixa = () => {
    let { conta} = state

    if (conta.tipo !== 'RECEBER') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Para incluir ao caixa, somente contas A RECEBER!'
        }
      }))

      return false
    }

    return true
  }

  const updateFieldPessoa = (event, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        cliente: {
          pessoa_id: '',
          tipo: '',
          flags: [],
          nome: '',
          cpf_cnpj: '',
          endereco: '',
          telefone: '',
          email: '',
          ativo: ''
        }
      }))

      return false
    } 
    setState(prevState => ({...prevState,
      cliente: value
    }))
  }

  const updateFieldItem = (e, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        itemSelecionado: {
          id: '',
          descricao: '',
          planocontas_id: '',
          plano_contas: {}
        }
      }))

      return false
    } 

    const {conta} = state

    conta.item_id = value.id
      
    let centro_custos = value.plano_contas.centro_custos.filter(param => param.ativo).map((centro, key) => {
      return {
        id: centro.id,
        departamento_id: centro.centro_custo.departamento_id,
        departamento: centro.centro_custo.departamento.descricao,
        centrocusto_id: centro.centro_custo.id,
        centrocusto: centro.centro_custo.descricao,
        valor_percentual: centro.valor_percentual.toFixed(2),
        valor_rateio: 0,
        ativo: centro.ativo,
        index: key
      }
    })

    let valor_total = parseFloat(conta.valortotal.replaceAll(".", "").replaceAll(",", "."))
    for (let i = 0; i < centro_custos.length; i++) {
      centro_custos[i].valor_rateio =  real((valor_total * centro_custos[i].valor_percentual)/100)
    }

    setState(prevState => ({...prevState,
      itemSelecionado: value,
      centro_custos
    }))
  }

  const updateParcelado = () => {
    let conta = state.conta

    let event = {
      target: {
        value: 1
      }
    }
    
    conta.parcelado = !conta.parcelado
    conta.recorrencia = false
    setState(prevState => ({...prevState,conta}))
    
    mudarParcelas(event)
  }

  const updateRecorrencia = () => {
    let conta = state.conta
    conta.recorrencia = !conta.recorrencia
    conta.parcelado = false
    
    conta.qtd_parcelas = 1
    conta.recorrenciaACada = 1
    conta.tipoRecorrencia = 'M'
    conta.qtdRecorrencia = 1
    
    setState(prevState => ({...prevState,conta}))

    let event = {
      target: {
        value: 1
      }
    }

    mudarParcelas(event)
  }

  const criaUrl = () => {
    const filtro = JSON.parse(localStorage.getItem('filtro_contaspr'))

    let url = `/conta_pagar_receber?limit=${filtro.limit}&offset=${filtro.offset}&data_por=${filtro.data_por}&data_inicial=${filtro.data_inicial}&data_final=${filtro.data_final}`

    if(filtro.tipo !== ''){
      url += `&tipo=${filtro.tipo}`
    }
    if(filtro.descricao !== ''){
      url += `&descricao=${filtro.descricao}`
    }
    if(filtro.pessoa_id !== ''){
      url += `&pessoa_id=${filtro.pessoa_id}`
    }
    
    if(filtro.formas_pagto.length > 0){
      let texto = ''

      for (let i = 0; i < filtro.formas_pagto.length; i++) {
        if((i+1) === filtro.formas_pagto.length){
          texto += `${filtro.formas_pagto[i].id}`
        }else{
          texto += `${filtro.formas_pagto[i].id},`
        }
      }

      url += `&formas_pagto=${texto}`
    }

    if(filtro.situacoes.length > 0){
      let texto = ''

      for (let i = 0; i < filtro.situacoes.length; i++) {
        if((i+1) === filtro.situacoes.length){
          texto += `${filtro.situacoes[i].id}`
        }else{
          texto += `${filtro.situacoes[i].id},`
        }
      }

      url += `&situacoes=${texto}`
    }

    return url
  }

  const voltar = () => {
    const ultima_tela_financeiro = JSON.parse(localStorage.getItem('ultima_tela_financeiro'))

    if (ultima_tela_financeiro.tela === 'contasPR') {
      let url = criaUrl()
      navigate(url);
    } else if (ultima_tela_financeiro.tela === 'cliente') {
      navigate(`/cliente/view/${ultima_tela_financeiro.id}/financeiro`)
    } else if(ultima_tela_financeiro.tela === 'extrato_conciliacao'){
      navigate(`/movimentacao_financeira/importar_extrato`)
    } else if (ultima_tela_financeiro.tela === 'preContasPR') {
      navigate(`/preContasPR`)
    }
  }

  const handleCloseModalPessoa = () => {
    setState(prevState => ({...prevState,
      modalSelecionaPessoa: false
    }))
  }

  const handleCloseConfirmacao = () => {
    setState(prevState => ({...prevState,
      modalConfirmacao: {
        mensagem: '',
      },
      modalConfirmacaoOpen: false,
      disabledButtons: false
    }))
  }

  const salvar = async (baixar) => {
    setState(prevState => ({...prevState,
      disabled: true,
      baixar
    }))
    
    const { perfilLogado } = state
    let {conta, centro_custos, cliente, formasPagamento, itemSelecionado, contasFinanceira} = state

    let modalConfirmarSalvar = false
    let mensagem = ''

    if (!verificaCaixa()) {
      setState(prevState => ({...prevState,disabledButtonsSalvar: false}))
      return false
    }

    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        },
        disabledButtonsSalvar: false,
        disabled: false
      }))
      return
    }

    for (const parcela of conta.parcelas) {
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(parcela.formapagto_id))[0]
    
      if(formaPagtoSelecionada.slug === 'boleto' && parcela.contfin_id === ''){
        setState(prevState => ({...prevState,
          disabledButtonsSalvar: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a Contas Bancárias no boleto.'
          },
          disabled: false
        }))
        return
      }

      const forma_selecionada = formasPagamento.list.filter(param => param.id === parseInt(parcela.formapagto_id))[0]
      const conta_selecionada = contasFinanceira.list.filter(param => param.id === parseInt(parcela.contfin_id))[0]

      if(
        moment(parcela.datavencimento).isBefore(new Date()) 
        && forma_selecionada.slug === 'boleto' 
        && conta_selecionada.conta_corrente.parceiro){
          setState(prevState => ({...prevState,
            disabledButtonsSalvar: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'A data de vencimento do boleto não pode ser inferior ao dia de hoje.'
            },
            disabled: false
          }))
          return
      }

      const ano = moment(parcela.datavencimento).format('YYYY')

      if(parseInt(ano) < 2000){
        if(!modalConfirmarSalvar){
          modalConfirmarSalvar = true
          mensagem = `Foi cadastrada uma parcela com data de vencimento para o ano de ${ano}, deseja confirmar o salvamento?`
        }
      }

    }

    console.log(itemSelecionado)

    if(itemSelecionado.plano_contas.centro_custos.length > 0){
      if(centro_custos.length === 0){
        setState(prevState => ({...prevState,
          disabledButtonsSalvar: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o centro de custo.'
          },
          disabled: false
        }))
        return
      }
    }

    if(centro_custos.length > 0){
      const valorTotalRateio = somarValores(centro_custos.map(value => {return parseFloat(value.valor_percentual)}))

      if(valorTotalRateio < 100){
        setState(prevState => ({...prevState,
          disabledButtonsSalvar: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A porcentagem do rateio do centro de custo não pode ser inferior a 100%.'
          },
          disabled: false
        }))
        return
      }else if(valorTotalRateio > 100){
        setState(prevState => ({...prevState,
          disabledButtonsSalvar: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A porcentagem do rateio do centro de custo não pode ser superior a 100%.'
          },
          disabled: false
        }))
        return
      }
    }

    if(cliente.pessoa_id === ''){
      setState(prevState => ({...prevState,
        disabledButtonsSalvar: false,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Selecionar o Cliente/Fornecedor!'
        },
        disabled: false
      }))
      return
    }

    if (perfilLogado === 'caixa' && !validateFormCaixa()) {
      setState(prevState => ({...prevState,disabledButtonsSalvar: false}))
      return false
    }

    if(modalConfirmarSalvar){
      setState(prevState => ({...prevState,
        modalConfirmacao: {
          mensagem,
        },
        modalConfirmacaoOpen: true,
      }))
    }else{
      await continuarSalvamento(true, baixar)
    }

  }
  
  const continuarSalvamento = async (value, baixar) => {
    if(value){
      handleCloseConfirmacao()
      const { perfilLogado } = state
      let {conta, centro_custos, cliente, itens_receita_despesa} = state

      try {
        let dados = { ...conta }
        let {formasPagamento} = state
  
        let valortotal = parseFloat(dados.valortotal.replaceAll(".", "").replaceAll(",", "."))
        valortotal = dados.tipo === 'PAGAR' ? (valortotal < 0 ? valortotal : (valortotal * -1)) :(valortotal > 0 ? valortotal : (valortotal * -1)) 
  
        let dadosAux = {
          descricao: dados.descricao,
          tipo: dados.tipo,
          valortotal,
          numparcela: dados.numparcela,
          item_id: state.itemSelecionado.id,
          tipo_item: 'ITEMRECDESP',
          unidade_id: dados.unidade_id,
          pessoa_id: cliente.pessoa_id,
          recorrencia: dados.recorrencia,
          qtdRecorrencia: dados.qtdRecorrencia,
          recorrenciaACada: dados.recorrenciaACada,
          tipoRecorrencia: dados.tipoRecorrencia,
          data_documento: dados.data_documento,
          centros_custo: centro_custos.map(centro => {
            let valor_rateio =  parseFloat(centro.valor_rateio.replaceAll(".", "").replaceAll(",", "."))
            valor_rateio = dados.tipo === 'PAGAR' ? (valor_rateio < 0 ? valor_rateio : (valor_rateio * -1)) :(valor_rateio > 0 ? valor_rateio : (valor_rateio * -1))
            return {
              valor_rateio,
              valor_percentual: parseFloat(centro.valor_percentual),
              centrocusto_id: centro.centrocusto_id
            }
          }),
          parcelas: dados.parcelas.map(parcela => {
            let valorparcela = parseFloat(parcela.valorparcela.replaceAll(".", "").replaceAll(",", "."))
            valorparcela = dados.tipo === 'PAGAR' ? (valorparcela < 0 ? valorparcela : (valorparcela * -1)) :(valorparcela > 0 ? valorparcela : (valorparcela * -1)) 
  
            return {
              datavencimento: parcela.datavencimento,
              datavencimento_orig: parcela.datavencimento,
              valorparcela,
              num: parcela.index,
              documento: parcela.documento,
              contfin_id: parcela.contfin_id,
              formas_pgto: [
                {
                  formapagto_id: parseInt(parcela.formapagto_id),
                }
              ]
            }
          })
        }

        if(dados.pre_conta_id){
          dadosAux.pre_conta_id = dados.pre_conta_id
        }
  
        if(dadosAux.centros_custo.length === 0){
          delete dadosAux.centros_custo
        }
  
        if(dadosAux.data_documento === ''){
          delete dadosAux.data_documento
        }
  
        let valor_total_parcelas = parseFloat((somarValores(dadosAux.parcelas.map(parcela => {return parcela.valorparcela}))).toFixed(2))
  
        if(valor_total_parcelas < valortotal){
          setState(prevState => ({...prevState,
            disabledButtonsSalvar: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: dados.tipo === 'PAGAR' ? 'O valor somado das parcelas não pode ser superior ao valor total da conta.' : 'O valor somado das parcelas não pode ser inferior ao valor total da conta.'
            },
            disabled: false
          }))
          return
        }
  
        if(valor_total_parcelas > valortotal){
          setState(prevState => ({...prevState,
            disabledButtonsSalvar: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: dados.tipo === 'PAGAR' ? 'O valor somado das parcelas não pode ser inferior ao valor total da conta.' : 'O valor somado das parcelas não pode ser superior ao valor total da conta.'
            },
            disabled: false
          }))
          return
        }
  
        for (let i = 0; i < dadosAux.parcelas.length; i++) {
          if (dadosAux.parcelas[i].documento === '') {
            delete dadosAux.parcelas[i].documento
          }
        }
  
        if (perfilLogado === 'caixa') {
          dadosAux.caixa = state.ultimoCaixa.id
        }
  
        if (props.cliente) {
          dadosAux.cliente = props.cliente._id
        }
  
        const {data: contaResp} = await axios.post(`${window._env_.REACT_APP_API_URL}/contasPagarReceber/trasaction`, dadosAux, getToken())
  
        if(!dadosAux.recorrencia){
          let respParcelas = contaResp.parcelas
    
          for (const parcela of dadosAux.parcelas) {
            for (const forma_pgto of parcela.formas_pgto) {
              const formaPagtoAux = formasPagamento.list.filter(param => param.id === forma_pgto.formapagto_id)[0]
  
              const indexParcela = respParcelas.findIndex(param => param.num === parcela.num)
              
              let dadosGera = {
                parcelas : [respParcelas[indexParcela]]
              }
              
              if(formaPagtoAux.slug === 'boleto' && dadosAux.tipo === 'RECEBER'){
                await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, dadosGera, getToken())
              }else if (formaPagtoAux.slug === 'pix' && dadosAux.tipo === 'RECEBER'){
                // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, dadosGera, getToken())
              }
              
            }
    
          }
        }else{
          for (const conta of contaResp) {
            let respParcelas = conta.parcelas

            for (const parcela of dadosAux.parcelas) {
              for (const forma_pgto of parcela.formas_pgto) {
                const formaPagtoAux = formasPagamento.list.filter(param => param.id === forma_pgto.formapagto_id)[0]
    
                const indexParcela = respParcelas.findIndex(param => param.num === parcela.num)
                
                let dadosGera = {
                  parcelas : [respParcelas[indexParcela]]
                }
                
                if(formaPagtoAux.slug === 'boleto' && dadosAux.tipo === 'RECEBER'){
                  await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, dadosGera, getToken())
                }else if (formaPagtoAux.slug === 'pix' && dadosAux.tipo === 'RECEBER'){
                  // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, dadosGera, getToken())
                }
                
              }
      
            }
          }
        }
  
        if(baixar){
          const {salvarBaixa} = state

          let dadosConta = {
            conta: {
              contpr_id: contaResp.id,
              contpr_descricao: contaResp.descricao,
              plancontas_id: itens_receita_despesa.list.filter(param => param.id === dadosAux.item_id)[0].planocontas_id,
              centros_custo: centro_custos.map(centro => {
                let valor_rateio =  parseFloat(centro.valor_rateio.replaceAll(".", "").replaceAll(",", "."))
                valor_rateio = dados.tipo === 'PAGAR' ? (valor_rateio < 0 ? valor_rateio : (valor_rateio * -1)) :(valor_rateio > 0 ? valor_rateio : (valor_rateio * -1))
                return {
                  valor_rateio,
                  valor_percentual: parseFloat(centro.valor_percentual),
                  centrocusto_id: centro.centrocusto_id
                }
              }),
            },
            parcela: {
              baixado: true,
              datavencimento: dadosAux.parcelas[0].datavencimento,
              valorparcela: dadosAux.parcelas[0].valorparcela,
              datapagamento: salvarBaixa.data_pagamento,
              multajuros: 0,
              num: dadosAux.parcelas[0].num,
              situacao: 'BAIXADO',
              formas_pgto:[{
                formapagto_id: parseInt(salvarBaixa.formapagto_id),
                contfin_id: parseInt(salvarBaixa.contfin_id),
                valor_pagamento: dadosAux.parcelas[0].valorparcela
              }]
            },
            acao_alteracao: 'apenas_esta'
          }
  
          let movimentacao_extrato = JSON.parse(localStorage.getItem('movimentacao_extrato'))

          if(movimentacao_extrato){
            dadosConta.extrato_id = movimentacao_extrato.id
          }

          await axios.put(`${window._env_.REACT_APP_API_URL}/parcelaConta/${contaResp.parcelas[0].id}`, dadosConta, getToken())
      
          setState(prevState => ({...prevState,
            disabledButtonsSalvar: false, 
            disabled: false, 
            modalSalvarBaixa: false,
            salvarBaixa: initialState.salvarBaixa,
            alerta: {
              open: true,
              severity: 'success',
              message: 'Parcela baixada com sucesso.'
            }
          }))
  
          if(movimentacao_extrato){
            setTimeout(() => {
              localStorage.removeItem('movimentacao_extrato')
              localStorage.removeItem('dados_contaspr')
      
              navigate("/movimentacao_financeira/importar_extrato");
            }, 2000)
          }else{
            backPage()
          }
  
        }else{
          setState(prevState => ({...prevState,
            conta: initialState.conta,
            alerta: {
              open: true,
              severity: 'success',
              message: 'Cadastro realizado com sucesso.'
            },
            modalSalvarBaixa: false,
            disabled: false
          }))
          backPage()
        }
  
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          disabled: false,
          disabledButtonsSalvar: false
        }))
      }
    }else{
      handleCloseConfirmacao()
    }
  }

  const salvarBaixar = async () => {
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        disabledButtons: false,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    let { conta, formasPagamento } = state

    const itens = formasPagamento.list
    const indexForma = itens.findIndex(param => param.id === parseInt(conta.parcelas[0].formapagto_id))

    setState(prevState => ({...prevState,
      salvarBaixa:{
        id: '',
        formapagto_id: conta.parcelas[0].formapagto_id,
        valor_pagamento: real(0),
        descricao: formasPagamento.list[indexForma].descricao,
        hab_caixa: false,
        data_pagamento: conta.parcelas[0].datavencimento,
        tipo_conta: conta.parcelas[0].habilita,
        contfin_id: conta.parcelas[0].contfin_id
      },
      modalSalvarBaixa: true
    }))
  }

  const cadastrarCliente = () => {
    let {conta, itemSelecionado, centro_custos} = state

    localStorage.setItem('dados_conta', JSON.stringify(conta));
    localStorage.setItem('itemSelecionado', JSON.stringify(itemSelecionado));
    localStorage.setItem('centro_custos', JSON.stringify(centro_custos));
    localStorage.setItem('ultima_tela_cliente', '/conta_pagar_receber/cadastro');
    
    navigate("/cliente/cadastro");
    return;
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }
  }

  const consultarPessoa = async (nome) => {
    setState(prevState => ({...prevState,loadingPessoas: true}))
    try {
      let {conta} = state

      let filtroPessoa = {
        unidadesnegocio: [conta.unidade_id],
        ativo: 'Sim',
        nome: nome,
        cpf_cnpj: '',
        tipo: '',
        flag:''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtroPessoa, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: `${pessoa.nome} ${pessoa.sobrenome || ''}`,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.cpf_cnpj ? cpf.format(pessoa.cpf_cnpj) : 'N/A') : (pessoa.cpf_cnpj ? format(pessoa.cpf_cnpj) : 'N/A'),
        })
      })

      setState(prevState => ({...prevState,
        pessoas: {
          list: pessoas
        },
        pessoasFiltrado: {
          list: pessoas
        },
        loadingPessoas: false
      }))

      return pessoas

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({...prevState,
        salvarBaixa:{
          id: '',
          formapagto_id: '',
          valor_pagamento: real(0),
          descricao: '',
          hab_caixa: false,
          data_pagamento: '',
          contfin_id: ''
        },
        conta: {
          descricao: "",
          tipo: "",
          valortotal: "",
          numparcela: 1,
          item_id: "",
          qtd_parcelas: 1,
          recorrenciaACada: 1,
          tipoRecorrencia: 'M',
          data_documento: '',
          unidade_id: '',
          parcelas: [
            {
              baixado: "",
              datavencimento: "",
              datavencimento_orig: "",
              valorparcela: "",
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: "",
              parcela_editada: false,
              index: 1
            }
          ],
          parcelado: false,
          recorrencia: false,
          qtdRecorrencia: 1
        },
        centro_custo:{
          id: '',
          departamento_id: '',
          departamento: '',
          centrocusto_id: '',
          centrocusto: '',
          valor_percentual: 0,
          valor_rateio: 0,
          ativo: true,
          index: ''
        }
      }))

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        let unidades_filter = unidades.filter(param => (param.tipo === "MANTENEDOR" || param.tipo === 'UNIDADE')).sort((a, b) => (a.numero > b.numero) ? 1 : (b.numero > a.numero) ? -1 : 0).map(unidade => {
          return {
            id: unidade.id,
            descricao: `${unidade.numero} - ${unidade.descricao}`,
            tipo: unidade.tipo
          }
        })

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades_filter
          }
        }))

        if(unidades_filter.length === 1){
          const {conta} = state
          conta.unidade_id = unidades[0].id

          // try {
            
          //   let filtro = state.filtro
      
          //   filtro.unidadesnegocio = [unidades[0].id]
      
          //   const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/list`, filtro, getToken())
      
          //   const pessoas = []
      
          //   data.forEach(pessoa => {
          //     pessoas.push({
          //       pessoa_id: pessoa.id,
          //       tipo: pessoa.tipo,
          //       nome: pessoa.tipo === 'FISICA' ? `${pessoa.fisica.nome} ${pessoa.fisica.sobrenome || ''}` : `${pessoa.juridica.nomefantasia} - ${pessoa.juridica.razaosocial}`,
          //       cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.fisica.cpf ? cpf.format(pessoa.fisica.cpf) : 'N/A') : (pessoa.juridica.cnpj ? format(pessoa.juridica.cnpj) : 'N/A'),
          //       flags: pessoa.flags.map(value => value.flag),
          //       endereco: pessoa.enderecos[0] ? `${pessoa.enderecos[0].logradouro} ${pessoa.enderecos[0].numero}, ${pessoa.enderecos[0].bairro}, ${pessoa.enderecos[0].cidade} - ${pessoa.enderecos[0].estado}` : 'N/A',
          //       telefone: pessoa.telefones[0] ? pessoa.telefones[0].numero : 'N/A',
          //       email:pessoa.emails[0] ? pessoa.emails[0].email : 'N/A',
          //       ativo: pessoa.ativo
          //     })
          //   })
      
          //   setState(prevState => ({...prevState,
          //     pessoas: {
          //       list: pessoas
          //     },
          //     pessoasFiltrado: {
          //       list: pessoas
          //     }
          //   }))
      
          // } catch (error) {
          //   console.log(error)
          //   setState(prevState => ({...prevState,
          //     loadingPessoas: false,
          //     alerta: {
          //       open: true,
          //       severity: 'error',
          //       message: error.response ? error.response.data.message : 'Erro Interno'
          //     }
          //   }))
          // }

          try {
            const dados = {
              unidadesnegocio: [parseInt(unidades[0].id)]
            }
      
            const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/list`, dados, getToken())
      
            const departamentos = []
            
            data.forEach(departamento => {
              if(departamento.unidade){
                departamentos.push({
                  id: departamento.id,
                  descricao: departamento.descricao,
                  unidade: departamento.unidade.descricao ? departamento.unidade.descricao : (departamento.unidade.pessoa.tipo === 'FISICA' ? departamento.unidade.pessoa.fisica.nome : departamento.unidade.pessoa.juridica.nomefantasia),
                  centro_custos: departamento.centro_custos,
                  ativo: departamento.ativo
                })
              }
            })
      
            setState(prevState => ({...prevState,
              departamentos: {
                list: departamentos
              }
            }))
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? error.response.data.message : 'Erro Interno'
              }
            }))
          }

          try {
            const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${parseInt(unidades[0].id)}`, getToken())
      
            setState(prevState => ({...prevState,
              contasFinanceira: {
                list: contasFinanceira
              }
            }))
      
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? error.response.data.message : 'Erro Interno'
              }
            }))
          }

          setState(prevState => ({...prevState,conta}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      const caixa = JSON.parse(localStorage.getItem('caixa'))
      setState(prevState => ({...prevState, logado: { caixa } }))

      const dataHoje = new Date()
      let conta = { ...state.conta }

      conta.parcelas[0].datavencimento = moment(dataHoje).format('YYYY-MM-DD')
      conta.parcelas[0].datavencimento_orig = moment(dataHoje).format('YYYY-MM-DD')

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contas-pagar-receber')[0]
      let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]

      if (permissoesCaixa) {
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      if (permissoesContasPR) {
        setState(prevState => ({...prevState,
          permissoesContasPR,
          perfilLogado: 'contas-pagar-receber'
        }))
      }

      const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa'))

      if(ultimoCaixa && ultimoCaixa['status_atual.status'] === 'ABERTO'){
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      setState(prevState => ({...prevState,
        ultimoCaixa: ultimoCaixa || {}
      }))

      try {
        const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

        setState(prevState => ({...prevState,
          formasPagamento: {
            list: formasPagamento.filter(param => param.ativo === true)
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      let pessoa_cadastrada = JSON.parse(localStorage.getItem('pessoa_cadastrada'))
      
      if(pessoa_cadastrada){
        
        conta = JSON.parse(localStorage.getItem('dados_conta'))

        let itens_receita_despesa = await consultarItens(conta.tipo)

        let pessoas = await consultarPessoa(pessoa_cadastrada.cpf ? pessoa_cadastrada.nome : pessoa_cadastrada.nomefantasia)
        let pessoa = pessoas.filter(param => param.pessoa_id === pessoa_cadastrada.pessoa_id)[0]

        let itemSelecionado = JSON.parse(localStorage.getItem('itemSelecionado'))
        let centro_custos = JSON.parse(localStorage.getItem('centro_custos'))

        if(itemSelecionado.id !== ''){
          conta.item_id = itemSelecionado.id
        }

        setState(prevState => ({...prevState,
          cliente:{
            ...pessoa
          },
          itemSelecionado: {...itemSelecionado},
          centro_custos:  [...centro_custos],
        }))

        localStorage.removeItem('pessoa_cadastrada');
        localStorage.removeItem('ultima_tela_cliente');
        localStorage.removeItem('dados_conta');
        localStorage.removeItem('itemSelecionado');
        localStorage.removeItem('centro_custos');
      }

      let conta_copiar = JSON.parse(localStorage.getItem('conta_copiar'))

      if(conta_copiar){
        conta = conta_copiar

        let itens_receita_despesa = await consultarItens(conta_copiar.tipo)
      
        if(conta.itemSelecionado && conta.centro_custos.length === 0){
          let index_item = itens_receita_despesa.findIndex(param => param.id ===  conta_copiar.itemSelecionado.id)
        
          if(index_item !== -1){
            let item = itens_receita_despesa[index_item]
    
            let centro_custos = item.plano_contas.centro_custos.filter(param => param.ativo).map((centro, key) => {
              return {
                id: centro.id,
                departamento_id: centro.centro_custo.departamento_id,
                departamento: centro.centro_custo.departamento.descricao,
                centrocusto_id: centro.centro_custo.id,
                centrocusto: centro.centro_custo.descricao,
                valor_percentual: centro.valor_percentual.toFixed(2),
                valor_rateio: 0,
                ativo: centro.ativo,
                index: key
              }
            })
        
            for (let i = 0; i < centro_custos.length; i++) {
              centro_custos[i].valor_rateio =  real((conta.valortotal * centro_custos[i].valor_percentual)/100)
            }
        
            setState(prevState => ({...prevState,
              centro_custos
            }))
    
            conta.item_id = item.id
        
            console.log(item)

            setState(prevState => ({...prevState,
              itemSelecionado: item
            }))
          }
    
        }

        setState(prevState => ({...prevState,
          cliente: conta_copiar.cliente,
          centro_custo: conta.centro_custos
        }))
      }

      let conta_pre_cadastro = JSON.parse(localStorage.getItem('conta_pre_cadastro'))

      if(conta_pre_cadastro){
        await consultarItens(conta_pre_cadastro.tipo)

        conta.descricao = conta_pre_cadastro.descricao
        conta.data_documento = conta_pre_cadastro.data_vencimento_sem_form
        conta.valortotal = real(parseFloat(conta_pre_cadastro.valor_conta.replaceAll("R$ ", "").replaceAll(".", "").replaceAll(",", ".")))
        conta.tipo = conta_pre_cadastro.tipo
        conta.unidade_id = conta_pre_cadastro.unidade_id
        conta.pre_conta_id = conta_pre_cadastro._id
        
        conta.parcelas[0].contfin_id = conta_pre_cadastro.conta_fin_id
        conta.parcelas[0].formapagto_id = conta_pre_cadastro.forma_pagto_id
        conta.parcelas[0].valorparcela = real(parseFloat(conta_pre_cadastro.valor_conta.replaceAll("R$ ", "").replaceAll(".", "").replaceAll(",", ".")))
        conta.parcelas[0].datavencimento = conta_pre_cadastro.data_vencimento_sem_form
        conta.parcelas[0].datavencimento_orig = conta_pre_cadastro.data_vencimento_sem_form
        conta.parcelas[0].habilita = conta_pre_cadastro.habilita
      
        let index_item = state.itens_receita_despesa.list.findIndex(param => param.id ===  conta_pre_cadastro.item_id)
        
        if(index_item !== -1){
          let item = state.itens_receita_despesa.list[index_item]

          let centro_custos = item.plano_contas.centro_custos.filter(param => param.ativo).map((centro, key) => {
            return {
              id: centro.id,
              departamento_id: centro.centro_custo.departamento_id,
              departamento: centro.centro_custo.departamento.descricao,
              centrocusto_id: centro.centro_custo.id,
              centrocusto: centro.centro_custo.descricao,
              valor_percentual: centro.valor_percentual.toFixed(2),
              valor_rateio: 0,
              ativo: centro.ativo,
              index: key
            }
          })
      
          for (let i = 0; i < centro_custos.length; i++) {
            centro_custos[i].valor_rateio =  real((conta.valortotal * centro_custos[i].valor_percentual)/100)
          }
      
          setState(prevState => ({...prevState,
            centro_custos
          }))

          conta.item_id = item.id
      
          setState(prevState => ({...prevState,
            itemSelecionado: item
          }))
        }
      
      }

      let dados_contaspr = JSON.parse(localStorage.getItem('dados_contaspr'))

      if(dados_contaspr){
        conta = dados_contaspr

        await consultarItens(conta.tipo)

        setState(prevState => ({...prevState,
          travarCampos: true
        }))
      }

      setState(prevState => ({...prevState,
        conta,
        loading: false
      }))

      localStorage.removeItem('conta_copiar')
      localStorage.removeItem('conta_pre_cadastro')
    };
    
    fetchData();
  }, []);

  const { conta, tab, baixar, disabled } = state

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Contas a Pagar/Receber...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading && 
          <React.Fragment>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11} xs={12} sm={10}>
                  <h1 className="titulo">Cadastro de Contas Pagar/Receber</h1>
                </Grid>
                <Grid item md={1} xs={12} sm={2}>
                  {disabled &&
                    <CircularProgress />
                  }
                </Grid>
              </Grid>
              <AppBar position="static" className="mg_top_10"> 
                <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="Dados da Conta" {...a11yProps(0)} />
                  <Tab label="Centro de Custo" {...a11yProps(1)} />
                </Tabs>
              </AppBar>

              <TabPanel value={tab} index={0}>
                <FormContaPagarReceber
                  logado={state.perfilLogado}
                  verificaCaixaInicial={() => verificaCaixaInicial(state)}
                  dados={conta}
                  updateField={e => updateField(e)}
                  updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
                  verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
                  mudarParcelas={e => mudarParcelas(e)}
                  recalcularParcela={(e, value) => recalcularParcela(e, value)}
                  itens_receita_despesa={state.itens_receita_despesa.list}
                  formasPagamento={state.formasPagamento.list}
                  contasFinanceira={state.contasFinanceira.list}
                  ultimoCaixa={state.ultimoCaixa}
                  cliente={state.cliente}
                  buscarPessoa={e => buscarPessoa()}
                  pessoasFiltrado={state.pessoas.list}
                  updateFieldPessoa={(e, value) => updateFieldPessoa(e, value)}
                  updateParcelado={ () => updateParcelado()}
                  updateRecorrencia={ () => updateRecorrencia()}
                  unidades={state.unidades.list}
                  marcaParcelas={(e, index) => marcaParcelas(e, index)}
                  marcaParcelasContaFin={(e, index) => marcaParcelasContaFin(e, index)}
                  marcaContasParcelas={(e, index) => marcaContasParcelas(e, index)}
                  updateFieldItem={(e, value) => updateFieldItem(e, value)}
                  itemSelecionado={state.itemSelecionado}
                  cadastrarCliente={e => cadastrarCliente()}
                  travarCampos={state.travarCampos}
                  updateFiltroPessoa={e => updateFiltroPessoa(e)}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <FormContaPRCentroCusto
                  centro_custos={state.centro_custos}
                  updateFieldCentro={(e, index) => updateFieldCentro(e, index)}
                  ajustaValorCentro={(e, index) => ajustaValorCentro(e, index)}
                  centro_custo={state.centro_custo}
                  departamentos={state.departamentos.list}
                  centro_custos_list={state.centros_custo_list.list}
                  updateFieldCentroCusto={e => updateFieldCentroCusto(e)}
                  addCentroCusto={e => addCentroCusto()}
                  removeCentro={e => removeCentro(e)}
                  conta={conta}
                  disabledCampos={false}
                />
              </TabPanel>
              <hr className="mg_top_10"/>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10" spacing={1}>
                <Grid item md={2} xs={12} sm={4}>
                  <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIosIcon />} onClick={e => voltar()} disabled={disabled}>Voltar</Button>
                </Grid>
                {verificaCaixaInicial(state).libera &&
                  <React.Fragment>
                    <Grid item md={6} xs={false} sm={4}></Grid>
                    <Grid item md={2} xs={12} sm={4}>
                      {(conta.qtd_parcelas === 1 && !conta.recorrencia) &&
                        <React.Fragment>
                          {(conta.parcelas[0].formapagto_id !== '4' || conta.tipo !== "RECEBER") &&
                            <Button color='success' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => salvarBaixar(e)} disabled={disabled}>Salvar e Baixar</Button>
                          }
                        </React.Fragment>
                      }
                    </Grid>
                    <Grid item md={2} xs={12} sm={4}>
                      <Button 
                      fullWidth 
                      color="primary" 
                      variant="contained" 
                      className="btn_salvar" 
                      size="small" 
                      startIcon={<SaveIcon />} 
                      onClick={e => salvar(false)} 
                      disabled={disabled || state.travarCampos}>
                        Salvar
                      </Button>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>

              <ModalSimple 
                open={state.modalCaixaOpen} 
                dados={state.modalCaixa} 
                handleClose={e => handleClose(e)} 
                updateData={e => updateField(e)} 
                confirmar={e => confirmarCaixa(e)} />

              <ModalConfirmacao
                open={state.modalConfirmacaoOpen} 
                dados={state.modalConfirmacao} 
                handleClose={e => handleCloseConfirmacao(e)}  
                confirmar={e => continuarSalvamento(e, baixar)} />

              <ModalSalvarBaixa 
                open={state.modalSalvarBaixa} 
                dados={state.salvarBaixa} 
                handleClose={e => handleCloseSalvarBaixa(e)} 
                updateField={e => updateFieldBaixaParcela(e)}
                contasFinanceira={state.contasFinanceira.list}
                formasPagamento={state.formasPagamento.list}
                conta={conta}
                confirmar={e => confirmarBaixaSalvar(e)}
                disabledButtonsSalvar={state.disabledButtonsSalvar}
                loadingPessoas={state.loadingPessoas}
                travarCampos={state.travarCampos}
              />

              <ModalSelecionaPessoa 
                open={state.modalSelecionaPessoa} 
                handleClose={e => handleCloseModalPessoa()} 
                updateFiltro={e => updateFiltro(e)}
                filtro={state.filtro}
                filtrocliente={state.filtrocliente}
                filtrarCliente={e => filtrarCliente()}
                loadingPessoas={state.loadingPessoas}
                pessoasFiltrado={state.pessoasFiltrado.list}
                selecionarPessoa={e => selecionarPessoa(e)}
                desabilitaFlag={false}
              />

          </React.Fragment>
        }

      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} />
    </div>
  )
}

export default CadastroContasPR